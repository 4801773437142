/* eslint-disable max-len */
import { number, string } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/constants'

export const ArrowIconSvg = styled.svg`
  height: ${prop('size', 48)}px;
  width: ${prop('size', 48)}px;
  .a,
  .b {
    fill: none;
  }
  .a {
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 1.5px;
  }
`

function ArrowIcon({ color, size }) {
  return (
    <ArrowIconSvg
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      size={size}
      viewBox="0 0 48 48"
    >
      <g transform="translate(-312 -560)">
        <g transform="translate(333 578)">
          <path
            className="a"
            d="M0,3.161l5.9-6-5.9-6"
            transform="translate(0 8.839)"
          />
        </g>
        <rect
          className="b"
          width="48"
          height="48"
          transform="translate(312 560)"
        />
      </g>
    </ArrowIconSvg>
  )
}

ArrowIcon.propTypes = {
  color: string,
  size: number,
}

export default ArrowIcon
