import styled, { keyframes } from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../stories/fundamentals/colors-styles/constants'

const DummyContent = styled.div`
  grid-area: main;
  margin: auto 0;
`
const LoaderWrapper = styled.div`
  background: ${prop('bgColor', colorsOptions.greys.white)};
  height: 100vh;
  width: 100%;
  &.noscroller {
    height: ${({ height }) => (height ? `${height}px` : 'calc(100vh - 50px)')};
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9999;
  }
`

const LoaderWrapperTwo = styled.div`
  background: ${prop('bgColor', colorsOptions.greys.white)};
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &.noscroller {
    height: calc(100vh - 50px);
  }
`

const NoBgLoaderWrapper = styled.div`
  height: ${({ height }) => (height ? `${height}px` : '100vh')};
  width: 100%;
  &.noscroller {
    height: calc(100vh - 50px);
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9999;
  }
`

const spinAnimationKeyframe = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Loader = styled.div`
  &.loader {
    position: absolute;
    top: 47%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
    text-align: center;
    border: 5px solid ${colorsOptions.greys['grey-60']};
    border-radius: 50%;
    border-top: 5px solid ${colorsOptions.greys['grey-100']};
    width: 50px;
    height: 50px;
    animation: ${spinAnimationKeyframe} 2s linear infinite;
  }
`

const LoaderText = styled.div`
  position: absolute;
  top: 57%;
  margin-left: 1px;
  text-align: center;
  left: auto;
  width: 100%;
`

const BaseLoader = styled.div`
  opacity: ${prop('opacity', 1)};
  border-radius: 50%;
  border: ${prop('border', '5')}px solid ${colorsOptions.greys['grey-60']};
  border-top: ${prop('border', '5')}px solid ${colorsOptions.greys['grey-100']};
  width: ${prop('width', 50)}px;
  height: ${prop('height', 50)}px;
  animation: ${spinAnimationKeyframe} 2s linear infinite;
  display: block;
`

const InlineLoader = styled(BaseLoader)`
  margin: 0 auto;
  float: ${prop('float', 'none')};
`

const OverlayLoader = styled(BaseLoader)`
  position: absolute;
  top: 40%;
  left: 45%;
  z-index: 10;
  transform: translate(-50%, -50%);
`

const SemiOpaqueWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  & > div {
    width: 100%;
    height: 100%;
    position: relative;
  }
`

const OpaqueScreen = styled.div`
  background-color: ${colorsOptions.greys.white};
  opacity: 0.6;
  width: 100%;
  height: 100%;
`

export {
  DummyContent,
  LoaderWrapper,
  LoaderWrapperTwo,
  NoBgLoaderWrapper,
  Loader,
  LoaderText,
  InlineLoader,
  OverlayLoader,
  SemiOpaqueWrapper,
  OpaqueScreen
}