import { put, takeLatest, call } from 'redux-saga/effects'

import { login } from '../Authentication/actions'
import * as actionTypes from './constants'
import { retry } from '../../utils/retrySaga'
import { callAPI } from '../../utils/callAPI'

export function * getInvitedUserData(action) {
  const { token, callback } = action.userData
  try {
    const response = yield call(callAPI, {
      method: 'get',
      url: '/api/v2/users/invited/email',
      params: {
        token
      }
    })

    if (callback && response.data) {
      return callback(
        actionTypes.callbackStatus.SUCCESS,
        'success',
        response.data
      )
    }

    return callback(actionTypes.callbackStatus.ERROR, 'request error')
  } catch (err) {
    const retried = yield call(retry, err, verifyEmailIsAvailable, action)
    if (!retried) {
      if (callback) {
        return callback(actionTypes.callbackStatus.ERROR, 'request error')
      }
    }
  }
}

export function * getInvitedUserDataWatcher() {
  yield takeLatest(actionTypes.GET_INVITED_USER_DATA, getInvitedUserData)
}

export function * verifyEmailIsAvailable(action) {
  const { email, callback } = action.userData
  try {
    const { data: emailAlreadyRegistered, status: availableEmailStatus } =
      yield call(callAPI, {
        method: 'get',
        url: '/api/users/email',
        params: {
          email
        }
      })

    if (callback && availableEmailStatus === 200) {
      if (emailAlreadyRegistered) {
        return callback(
          actionTypes.callbackStatus.ERROR,
          'email already registered'
        )
      } else {
        const sendVerificationCodeAction = {
          type: actionTypes.SEND_VERIFICATION_CODE,
          userData: { email, callback },
          retries: 1
        }

        yield sendVerificationCode(sendVerificationCodeAction)
        return
      }
    }

    return callback(actionTypes.callbackStatus.ERROR, 'request error')
  } catch (err) {
    const retried = yield call(retry, err, verifyEmailIsAvailable, action)
    if (!retried) {
      if (callback) {
        return callback(actionTypes.callbackStatus.ERROR, 'request error')
      }
    }
  }
}

export function * verifyEmailIsAvailableWatcher() {
  yield takeLatest(
    actionTypes.VERIFY_EMAIL_IS_AVAILABLE,
    verifyEmailIsAvailable
  )
}

export function * sendVerificationCode(action) {
  const { email, callback } = action.userData

  try {
    const { status } = yield call(callAPI, {
      method: 'post',
      url: '/api/v2/users/email/verify',
      data: {
        email
      }
    })

    if (status === 200) {
      callback(actionTypes.callbackStatus.SUCCESS, 'email was sent')
      return
    }

    return callback(actionTypes.callbackStatus.ERROR, 'request error')
  } catch (err) {
    const retried = yield call(retry, err, sendVerificationCode, action)
    if (!retried) {
      callback(actionTypes.callbackStatus.ERROR, 'request error')
    }
  }
}

export function * sendVerificationCodeWatcher() {
  yield takeLatest(actionTypes.SEND_VERIFICATION_CODE, sendVerificationCode)
}

export function * registerUser(action) {
  const {
    email,
    password,
    firstName,
    lastName,
    reCaptchaToken,
    organisationName,
    countryCode,
    phone,
    emailVerificationCode,
    language,
    callback
  } = action.userData
  try {
    const response = yield call(callAPI, {
      method: 'post',
      url: '/api/v2/register',
      data: {
        email,
        password,
        firstName,
        lastName,
        reCaptchaToken,
        organisationName,
        countryCode,
        phone,
        emailVerificationCode,
        language
      }
    })

    if (callback) {
      if (response.status === 201) {
        yield put(login(email, password))
      } else {
        callback(actionTypes.callbackStatus.ERROR, response.code)
      }
    }
  } catch (err) {
    const retried = yield call(retry, err, registerUser, action)

    if (!retried) {
      const errorCode = err?.response?.data?.code || 'unknown_error'
      callback(actionTypes.callbackStatus.ERROR, errorCode)
    }
  }
}

export function * registerUserWatcher() {
  yield takeLatest(actionTypes.REGISTER_USER, registerUser)
}

export default [
  getInvitedUserDataWatcher,
  registerUserWatcher,
  sendVerificationCodeWatcher,
  verifyEmailIsAvailableWatcher
]
