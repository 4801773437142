import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const NoPreview = styled.img`
  max-width: 100%;
  height: 40px;
`

/**
 * This image component supports multiple sources of images, if one is broken it uses the next.
 */
class Image extends React.Component {
  state = {
    loading: true,
    srcNumber: 0,
    retry: 0,
    retrying: false
  }

  MAX_RETRY = 3

  RETRY_DELAY = 1000

  injectNoPreviewPropsToState = (inject) => {
    const { noPreviewStyle, noPreviewImgStyle } = this.props
    if (inject) {
      this.setState({
        noPreviewStyle,
        noPreviewImgStyle
      })
    } else {
      this.setState({
        noPreviewStyle: undefined,
        noPreviewImgStyle: undefined
      })
    }
  }

  onLoad = (e) => {
    const { onLoad } = this.props
    this.setState({ loading: false, retrying: false }, () => {
      if (onLoad) {
        onLoad(e)
      }
    })
  }

  onError = (e) => {
    const { srcNumber, retry } = this.state
    const { maxRetry, retry: shouldRetry, sources, noPreview } = this.props
    this.injectNoPreviewPropsToState()
    if (srcNumber < sources.length - 1) {
      this.setState({
        srcNumber: srcNumber + 1
      })
    } else if (shouldRetry && retry < (maxRetry || this.MAX_RETRY)) {
      this.setState({ retry: retry + 1, retrying: true })
      e.persist()
      setTimeout(() => {
        e.target.src = `${sources[srcNumber]}&timeStamp=${new Date().getMilliseconds()}`
      }, this.RETRY_DELAY)
    } else {
      this.setState({ retrying: false })
      if (noPreview) {
        this.injectNoPreviewPropsToState(true)
        e.target.src = noPreview
      }
    }
  }

  render() {
    const { sources, width, height, alt, style, className, ...otherProps } =
      this.props

    const { srcNumber, retrying, loading, noPreviewStyle, noPreviewImgStyle } =
      this.state

    return (
      <div
        className={className}
        style={{ textAlign: 'center', ...style, ...noPreviewStyle }}
      >
        <NoPreview
          {...otherProps}
          src={sources[srcNumber]}
          onError={this.onError}
          onLoad={this.onLoad}
          width={loading && !retrying ? 0 : width}
          height={loading && !retrying ? 0 : height}
          alt={retrying ? '' : alt}
          {...noPreviewImgStyle}
        />
      </div>
    )
  }
}

Image.propTypes = {
  /** Array of src urls, useful if you have a fallback url for an image */
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** alt attribute of an html img tag */
  alt: PropTypes.string.isRequired,
  /** width attribute of an html img tag */
  width: PropTypes.number,
  /** height attribute of an html img tag */
  height: PropTypes.number,
  /** onLoad attribute of an html img tag */
  onLoad: PropTypes.func,
  /** style for the container of the image */
  style: PropTypes.object,
  /** maximum number of times to retry getting the first image on error */
  maxRetry: PropTypes.number,
  /** choose if to retry on error */
  retry: PropTypes.bool,
  /** Fallback url for an image when all retries fail */
  noPreview: PropTypes.string,
  className: PropTypes.string,
  noPreviewStyle: PropTypes.object,
  noPreviewImgStyle: PropTypes.object
}

export default Image
