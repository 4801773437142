import styled, { css, keyframes } from 'styled-components'
import { prop, ifNotProp, ifProp, withProp } from 'styled-tools'
import { AutoTextarea } from '../input/styled-components'
import Input from '../input'
import {
  sizing,
  weights
} from '../stories/fundamentals/text-styles/text-styles'
import colorsOptions, {
  applyOpacity
} from '../stories/fundamentals/colors-styles/constants'
import Button from '../Button'
import { Link } from 'react-router-dom'
import iconMember from '@images/ic_member.svg'

const TitleInput = styled(Input)`
  border-bottom: 1px solid ${colorsOptions.greys['grey-50']};
  ${ifNotProp(
    'editable',
    css`
      &:disabled {
        color: ${colorsOptions.greys['grey-100']};
      }
    `
  )}
`

const TitleTextarea = styled(AutoTextarea)`
  border-bottom: 1px solid ${colorsOptions.greys['grey-50']};
  ${ifNotProp(
    'editable',
    css`
      &:disabled {
        color: ${colorsOptions.greys['grey-100']};
      }
    `
  )}
`

const AssigneeOption = styled.div`
  display: inline-flex;
  position: relative;
  min-width: 0;
  flex-direction: column;
  padding: 11px 0;

  span {
    line-height: initial;
  }
  span:nth-child(2) {
    ${sizing['text-s']};
  }
`

const AssigneeDetail = styled.div`
  display: flex;
  width: 100%;
  ${prop('styles')}
`

const AssigneeType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 0;
`
const CategoryNameTwo = styled.span`
  ${sizing['text-l']};
  ${weights['text-600']};
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  ${prop('styles')}

  span.deactivatedInfo {
    color: ${colorsOptions.greys['grey-50']};
    margin-left: 5px;
    line-height: 26px;
  }
`
const Labelmsg = styled.span`
  ${sizing['text-r']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-50']};
  @media (max-width: 767px) {
    ${sizing['text-s']};
  }
  ${prop('styles')}
`

const FormContainer = styled.div`
  margin-bottom: 13px;
  ${withProp('marginTop', (marginTop) => `margin-top: ${marginTop}px`)};
  &.upmargin {
    margin-top: -5px;
  }
  &.upmarginmore {
    margin-top: -8px;
  }
  ${ifProp(
    'center',
    `display: flex;
        align-items: center;
        justify-content: center;`
  )}
`

const CheckboxWithLabelContainer = styled.div`
  display: flex;
  align-items: center;
`

const CustomBtn = styled(Button)`
  padding: 10px 20px;
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  width: ${({ inline }) => (inline ? 'auto' : '100%')};
  background: none;
  color: ${colorsOptions.greys['grey-100']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 20px;
  margin-top: 30px;
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}px;` : '')}
  border: 1px solid ${colorsOptions.greys['grey-30']};
  ${weights['text-600']};
  &:hover {
    background: ${colorsOptions.greys['grey-20']}; //not confirmed
    color: ${colorsOptions.greys['grey-60']};
    border-color: ${colorsOptions.greys['grey-30']};
  }
`

const CustomDescription = styled.p`
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  margin-bottom: 10px;
  ${ifProp('center', 'text-align: center;')}
`

const CustomInputs = styled(Input)`
  border-color: ${ifProp(
    'wrongInput',
    colorsOptions.secondaryColors.orange.strong,
    css`
      ${prop('borderColor', colorsOptions.greys['grey-50'])}
    `
  )};
  ${ifProp(
    'widthPercent',
    `width: ${prop('widthPercent')}%; display: inline-block;`
  )}
  &::placeholder {
    ${ifProp('placeholderStyle', prop('placeholderStyle'))}
  }
`

const ErrorMessage = styled.div`
  color: ${prop('color', colorsOptions.secondaryColors.orange.strong)};
  ${ifProp('center', 'text-align: center;')}
  ${ifProp('hide', 'visibility: hidden;')}
  ${sizing['text-r']};
  ${weights['text-600']};
  margin-bottom: ${prop('marginBottom', '5')}px;
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

const ErrorMessageBlock = styled.div`
  margin-bottom: 10px;
  text-align: center;
`

const FloatingLabel = styled.label`
  visibility: ${ifProp('visible', 'visible', 'hidden')};
  opacity: ${ifProp('visible', '1', '0')};
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  ${sizing['text-s']};
  color: ${prop('color')};
  ${ifProp(
    'maxLength',
    css`
      max-width: ${prop('maxLength')}px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `
  )}
`

const FloatingLabelGrey = styled(FloatingLabel)`
  ${sizing['text-r']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-50']};
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

const FloatingLabelTwo = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  ${sizing['text-s']};
`

const FormHeader = styled.h3`
  ${weights['text-600']};
  text-align: center;
`

const FullContainer = styled.div`
  min-height: ${ifProp('isInPopup', 'auto', '100vh')};
  background: ${colorsOptions.greys.white};
`

const InfoMessage = styled.span`
  ${sizing['text-r']};
  ${weights['text-600']};
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

const InsideContainer = styled.div`
  background: ${colorsOptions.greys.white};
  margin: 0 auto;
  max-width: ${prop('width', '350px')};
  width: 100%;
  padding: 0 6px;
  ${ifNotProp(
    'isInPopup',
    css`
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `
  )}
  @media(max-width: 767px) {
    position: inherit;
    padding-top: 80px;
    padding-bottom: 30px;
    transform: none;
    top: auto;
    left: auto;
  }
  ${ifProp(
    'maxHeight',
    css`
      @media (max-height: ${prop('maxHeight') - 15}px, min-width: 767px) {
        transform: translate(-50%, 0);
        top: 15px;
      }
    `
  )}
`

const LabelMessage = styled.span`
  ${ifProp('smaller', sizing['text-s'], sizing['text-r'])};
  ${weights['text-600']};
  color: ${prop('color', colorsOptions.greys['grey-50'])};
  cursor: ${ifProp('clickable', 'pointer', 'default')};
  ${ifProp('hide', 'visibility: hidden;')}
  word-break: normal;
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

const LogoImage = styled.img`
  max-width: 200px;
`

const LogoSection = styled.div`
  text-align: center;
  margin-bottom: 29px;
`

const InputWrapper = styled.div`
  padding: 4px 8px 0 8px;
  border-bottom: 1px solid
    ${ifProp(
      'error',
      colorsOptions.secondaryColors.red.strong,
      colorsOptions.greys['grey-60']
    )};
  background-color: ${applyOpacity(colorsOptions.greys['grey-60'], 0.1)};
`

const Wrapper = styled.div``

const ErrormsgWrapper = styled(ErrorMessage)`
  margin-left: 8px;
  ${sizing['text-xs']};
  margin-top: 2px;
  @media (max-width: 1024px) {
    ${sizing['text-xs']};
  }
`
const LabelmsgWrapper = styled(LabelMessage)`
  @media (max-width: 1024px) {
    ${sizing['text-xs']};
  }
`

const OtherContainer = styled.div`
  margin-top: 18px;
`

const OtherLinks = styled.div`
  ${weights['text-500']};
  color: ${colorsOptions.greys['grey-50']};
  ${sizing['text-s']};
  margin-bottom: 10px;
  display: block;
  text-align: center;
`

const Pills = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 0 !important;
`

const Pill = styled.div`
  background-color: ${ifProp(
    'outline',
    colorsOptions.greys['grey-10'],
    colorsOptions.greys['grey-60']
  )};
  border: 0.5px solid ${colorsOptions.greys['grey-60']};
  border-radius: 4px;
  font-weight: bold;
  ${sizing['text-s']};
  color: ${ifProp(
    'outline',
    colorsOptions.greys['grey-60'],
    colorsOptions.greys.white
  )};
  padding: 2px ${ifProp('canBeRemoved', 0, '4px')} 2px 4px;
  margin: 0 8px 8px 0;
  cursor: pointer;
  pointer-events: ${ifProp('outline', 'inherit', 'none')};

  & > button {
    display: inline-block;
    width: 17px;
    color: ${ifProp(
      'outline',
      colorsOptions.greys['grey-60'],
      colorsOptions.greys.white
    )};
    font-weight: bold;
    ${sizing['text-s']};
    text-align: center;
    pointer-events: all;
  }
`

const AddPillInput = styled.input`
  min-width: 80px;
  flex-grow: 1;
  border: 0.5px solid transparent;
  padding: 1px 0 1px 4px;
  margin: 0 8px 8px 0;
  color: ${colorsOptions.greys['grey-50']};
  ${sizing['text-s']};
  font-style: italic;
`

const SamplePill = styled(Pill)`
  display: inline;
  padding-right: 4px;
  margin: 0;
  pointer-events: none;
  color: ${ifProp(
    'active',
    colorsOptions.greys['grey-60'],
    colorsOptions.greys['grey-30']
  )};
  border-color: ${ifProp(
    'active',
    colorsOptions.greys['grey-60'],
    colorsOptions.greys['grey-30']
  )};
`

const StyledLinkRouter = styled(Link)`
  cursor: pointer;
  ${weights['text-500']};
  color: ${colorsOptions.docuToolsOrange.orange};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const StyledLink = styled.a`
  cursor: pointer;
  ${weights['text-500']};
  ${sizing['text-s']};
  color: ${colorsOptions.docuToolsOrange.orange};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const TagInputWrapper = styled.div`
  background-color: ${colorsOptions.greys.white};
  border: 1px solid
    ${ifProp(
      'tagError',
      colorsOptions.secondaryColors.red.normal,
      colorsOptions.greys['grey-30']
    )};
  border-radius: 8px;
  padding: 8px 16px 0 16px;
  display: flex;
  flex-wrap: wrap;
`
const Tag = styled.div`
  padding: 8px 16px;
  margin: 0 8px 8px 0;
  background-color: ${colorsOptions.greys['grey-10']};
  border-radius: 20px;
  border: 0.5px solid ${colorsOptions.greys['grey-30']};
  white-space: nowrap;
  ${sizing['text-s']};
  ${weights['text-500']};
`
const TagWrapper = styled.div`
  width: 100%;
`
const TagInputBox = styled.input`
  width: ${prop('width', '8px')};
  margin: 0 8px 8px 0;
  height: 31px;
  ${ifProp(
    'showPlaceholder',
    `
    width: 100%;
  `
  )}
  ${ifProp(
    'tagError',
    `
    color: ${colorsOptions.secondaryColors.red.strong};
  `
  )}
`
const AutoCompleteWrapper = styled.div`
  z-index: 99999999;
  position: relative;
  > div {
    position: absolute;
    width: 100%;
    background-color: ${colorsOptions.greys.white};
    border: 1px solid ${colorsOptions.greys['grey-30']};
    border-radius: 8px;
    margin-top: 5px;
  }
`
const AutoCompleteOption = styled.div`
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  ${ifProp(
    'last',
    css`
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    `
  )}
`
const LoadingAutocomplete = styled.div`
  width: 100%;
  padding: 16px;
`
const DeleteTag = styled.span`
  ${sizing['text-xs']};
  cursor: pointer;
  color: ${colorsOptions.greys['grey-60']};
  ${weights['text-700']};
`

const SpinAnimationKeyframe = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const InlineLoader = styled.div`
  border-radius: 50%;
  border: ${prop('border', '5')}px solid ${colorsOptions.greys['grey-60']};
  border-top: ${prop('border', '5')}px solid ${colorsOptions.greys['grey-100']};
  width: ${prop('width', 50)}px;
  height: ${prop('height', 50)}px;
  animation: ${SpinAnimationKeyframe} 2s linear infinite;
  display: block;
  margin: 0 auto;
`

const AutoCompleteOptionWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 56px;
  text-align: left;
  ${weights['text-600']};
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  ${ifProp(
    'last',
    css`
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    `
  )}
  ${ifNotProp(
    'disabled',
    css`
      &:hover,
      &:focus {
        background-color: ${colorsOptions.greys['grey-10']};
      }
    `,
    css`
      cursor: default;
    `
  )}
`

const Top = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${sizing['text-l']};
  ${ifProp(
    'highlighted',
    css`
      color: ${colorsOptions.docuToolsOrange.orange};
    `,
    css`
      ${ifProp('muted', `color: ${colorsOptions.greys['grey-30']}`)}
    `
  )}
`

const Sub = styled.div`
  ${sizing['text-s']};
  margin-top: 4px;
  ${ifProp(
    'highlighted',
    css`
      color: ${colorsOptions.docuToolsOrange.orange};
    `,
    css`
      ${ifProp(
        'muted',
        css`
          color: ${colorsOptions.greys['grey-30']};
        `,
        css`
          color: ${colorsOptions.greys['grey-60']};
        `
      )}
    `
  )}
`

const Role = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  margin-right: 0.5em;
  ${weights['text-500']};
  ${ifProp(
    'highlighted',
    css`
      color: ${colorsOptions.docuToolsOrange.orange};
    `,
    css`
      ${ifProp(
        'muted',
        css`
          color: ${colorsOptions.greys['grey-30']};
        `,
        css`
          color: ${colorsOptions.greys['grey-60']};
        `
      )}
    `
  )}
`

const Ico = styled.div`
  mask: url('${iconMember}');
  width: 40px;
  height: 56px;
  ${ifProp(
    'highlighted',
    css`
      background: ${colorsOptions.docuToolsOrange.orange};
    `,
    css`
      ${ifProp(
        'muted',
        css`
          background: ${colorsOptions.greys['grey-30']};
        `,
        css`
          background: ${colorsOptions.greys['grey-60']};
        `
      )}
    `
  )}
`

export {
  FloatingLabelGrey,
  TitleInput,
  TitleTextarea,
  AssigneeOption,
  AssigneeDetail,
  AssigneeType,
  CategoryNameTwo,
  Labelmsg,
  FormContainer,
  CheckboxWithLabelContainer,
  CustomBtn,
  CustomDescription,
  CustomInputs,
  ErrorMessage,
  ErrorMessageBlock,
  FloatingLabel,
  FloatingLabelTwo,
  FormHeader,
  FullContainer,
  InfoMessage,
  InsideContainer,
  LabelMessage,
  LogoImage,
  LogoSection,
  InputWrapper,
  Wrapper,
  ErrormsgWrapper,
  LabelmsgWrapper,
  OtherContainer,
  OtherLinks,
  Pills,
  Pill,
  AddPillInput,
  SamplePill,
  StyledLinkRouter,
  StyledLink,
  TagInputWrapper,
  Tag,
  TagWrapper,
  TagInputBox,
  AutoCompleteWrapper,
  AutoCompleteOption,
  LoadingAutocomplete,
  DeleteTag,
  SpinAnimationKeyframe,
  AutoCompleteOptionWrapper,
  Top,
  Sub,
  Role,
  Ico,
  InlineLoader
}
