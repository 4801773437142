import { func, object, oneOf } from 'prop-types'
import { connect } from 'react-redux'
import { features } from '../constants'
import * as actions from '../actions'
import { injectIntl } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import { makeSelectGetFeatureLabelIds } from '../selectors'
import Typography from '../../../components/Typography'
import { withHistory } from '../../../utils/hocs'
import {
  DialogButtonsContainer,
  StyledTextContainer
} from '../styledComponents'
import PopupNew from '../../../components/popup-new'
import {
  buttonSizeOptions,
  buttonVariantOptions
} from '../../../components/stories/atoms/button/constants'
import ButtonComponent from '../../../components/stories/atoms/button'
import TierIcon from './TierIcon'
import { isEmpty } from 'lodash'
import { featuresOptions } from '../labels'

const FeatureUnavailableDialog = ({
  featureSlug,
  featureSection,
  setUnavailableFeatureSlug,
  featureLabelIds,
  intl: { formatMessage },
  history: { push }
}) => {
  const selectedFeature = featureSection ?? featureSlug

  if (isEmpty(featureLabelIds) || !selectedFeature) {
    return
  }

  const {
    icon,
    currentTier,
    requiredTier,
    titleId,
    messageId,
    subMessageId,
    closeButton,
    actionButton
  } = featureLabelIds

  const close = () => {
    setUnavailableFeatureSlug(null)
  }

  const buttons = [
    closeButton && {
      children: formatMessage({ id: closeButton }),
      variant: buttonVariantOptions['TERTIARY-HOLLOW'],
      size: buttonSizeOptions.MEDIUM,
      onClick: close,
      fullWidth: true
    },
    actionButton && {
      children: formatMessage({ id: actionButton }),
      variant: buttonVariantOptions.PRIMARY,
      size: buttonSizeOptions.MEDIUM,
      onClick: () => {
        close()
        push('upgrade')
      },
      fullWidth: true
    }
  ].filter(Boolean)

  return (
    <PopupNew
      closePopup={close}
      title={formatMessage(
        { id: titleId },
        {
          feature: formatMessage({
            id: `restriction_${featuresOptions[selectedFeature]}`
          })
        }
      )}
      width={408}
      trueCenter
      bodyMaxHeight={767}
      noFooter={true}
    >
      <StyledTextContainer>
        <TierIcon icon={icon} />

        <Typography size="text-r" weight="text-500" as="p">
          {formatMessage(
            { id: messageId },
            {
              currentTier: (
                <Typography size="text-r" weight="text-700" as="span">
                  {currentTier} tool
                </Typography>
              ),
              feature: formatMessage({
                id: `restriction_${featuresOptions[selectedFeature]}`
              })
            }
          )}
        </Typography>
        <Typography size="text-r" weight="text-500" as="p">
          {formatMessage(
            { id: subMessageId },
            {
              requiredTier: (
                <Typography size="text-r" weight="text-700" as="span">
                  {requiredTier} tool
                </Typography>
              ),
              feature: formatMessage({
                id: `restriction_${featuresOptions[selectedFeature]}`
              })
            }
          )}
        </Typography>
        <DialogButtonsContainer>
          {buttons.map((propsButton, index) => (
            <ButtonComponent key={`${index}`} {...propsButton} />
          ))}
        </DialogButtonsContainer>
      </StyledTextContainer>
    </PopupNew>
  )
}

FeatureUnavailableDialog.propTypes = {
  featureSlug: oneOf(features),
  featureSection: oneOf(features),
  setUnavailableFeatureSlug: func,
  featureLabelIds: object
}

const mapStateToProps = createStructuredSelector({
  featureLabelIds: makeSelectGetFeatureLabelIds()
})

function mapDispatchToProps(dispatch) {
  return {
    setUnavailableFeatureSlug: (featureSlug) =>
      dispatch(actions.setUnavailableFeatureSlug(featureSlug))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withHistory(FeatureUnavailableDialog)))
