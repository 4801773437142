import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ifProp, prop } from 'styled-tools'
import {
  FullContainer as FormFieldFullContainer,
  LogoSection as FormFieldLogoSection,
  OtherContainer as FormFieldOtherContainer,
  CustomBtn as FormFieldCustomBtn,
  InfoMessage
} from '../../components/form-fields/styled-components'
import colorsOptions from '../../components/stories/fundamentals/colors-styles/constants'
import {
  sizing,
  weights
} from '../../components/stories/fundamentals/text-styles/text-styles'

export const FormContainer = styled.div`
  margin-bottom: 6px;
`

export const A = styled(Link)`
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 7px;
  color: ${colorsOptions.greys['grey-50']};
  text-decoration: none;
  ${sizing['text-s']};
  &:hover {
    text-decoration: underline;
  }
  ${weights['text-500']};
`

export const LanguageBox = styled.div`
  padding: 0;
`

export const InsideFormContainer = styled.div`
  position: relative;
`

export const Logout = styled.a`
  cursor: pointer;
`

export const AppAvailableDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background: ${colorsOptions.greys['grey-20']};
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  z-index: 99;

  .download {
    float: right;
    color: red;
  }
`

export const DownloadAppLink = styled.a`
  float: right;
  color: ${colorsOptions.secondaryColors.orange.normal};
  padding: 18px 16px;
  text-decoration: none;
  ${sizing['text-r']};
  &:hover {
    color: ${colorsOptions.secondaryColors.orange.normal};
  }
  display: inline-block;
  ${weights['text-600']};
`

export const Cross = styled.button`
  float: left;
  margin: 14px 0;
  border: none;
  width: 26px;
`

export const Logo = styled.div`
  float: left;
  margin: 10px 0;
  width: 36px;
  overflow: hidden;
  height: 36px;
  border-radius: 3px;
`

export const TitleText = styled.div`
  float: left;
  padding: 12px 16px;
  h3 {
    ${weights['text-600']};
    ${sizing['text-r']};
  }
  p {
    ${weights['text-500']};
    ${sizing['text-s']};
  }
`

export const Description = styled.div`
  text-align: center;
`

export const InsideContainer = styled.div`
  background: ${colorsOptions.greys.white};
  min-width: 350px;
  max-width: ${prop('maxWidth')}px;
  padding: 80px 6px 30px 6px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  @media (max-width: 767px) {
    justify-content: space-between;
    padding: 80px 16px 30px 16px;
    ${ifProp('isInPopup', 'min-height: unset; padding: 0 16px;')}
  }
  ${ifProp('isInPopup', 'min-height: unset; padding: unset;')}
`
export const OtherContainer = styled(FormFieldOtherContainer)`
  margin-top: 40px;
`
export const LogoSection = styled(FormFieldLogoSection)`
  margin-bottom: 80px;
  @media (max-width: 767px) {
    ${ifProp('isInPopup', 'margin-bottom: 0;')}
  }
  ${ifProp('isInPopup', 'margin-bottom: 28px;')}
`
export const CustomBtn = styled(FormFieldCustomBtn)`
  padding: 10px 42px;
  width: 60%;
  color: ${colorsOptions.docuToolsOrange.orange};
  border-color: ${colorsOptions.docuToolsOrange.orange};
  @media (max-width: 767px) {
    padding: 10px 42px;
  }
`
export const FullContainer = styled(FormFieldFullContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    padding: 0 42px;
  }
`
export const InfoMsgTwoFa = styled(InfoMessage)`
  display: block;
  max-width: 450px;
`

export const SingleSignOnWrapper = styled.div`
  margin-top: 18px;
  padding-top: 22px;
  border-top: 1px solid ${colorsOptions.greys['grey-30']};
  text-align: center;
  ${sizing['text-l']};
`
