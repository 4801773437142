import styled from 'styled-components'
import { ifProp, withProp } from 'styled-tools'

export const PageContainer = styled.div`
  padding: ${ifProp('isMobile', '0', '10px 48px 50px 48px')};
  position: relative;
  @media (max-width: 767px) {
    padding: 0;
  }
  ${withProp('marginTop', (marginTop) =>
    marginTop ? `margin-top: ${marginTop}px` : '',
  )};
  ${ifProp('nooverflow', 'overflow: hidden;')}
`