import { useBrowserStore } from '../../zustand/browser-store/use-browser-store'
import { PageContainer } from './styled-components'

const Page = ({ marginTop, nooverflow, ...props }) => {
  const { isMobile } = useBrowserStore()

  return (
    <PageContainer
      isMobile={isMobile}
      marginTop={marginTop}
      nooverflow={nooverflow}
      {...props}
    />
  )
}

export default Page
