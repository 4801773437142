import * as actionTypes from './constants'

export function loadOwnProjects(refresh) {
  return {
    type: actionTypes.LOAD_OWN_PROJECTS,
    refresh
  }
}
export function setOwnProjectSort(sort, sortReverse) {
  return {
    type: actionTypes.SET_OWN_PROJECT_SORT,
    sort,
    sortReverse
  }
}
export function ownProjectsLoaded(projects, timestamp, cached) {
  return {
    type: actionTypes.OWN_PROJECTS_LOADED,
    projects,
    timestamp,
    cached
  }
}
export function projectsLoadError(error) {
  return {
    type: actionTypes.PROJECTS_LOAD_ERROR,
    error
  }
}

export function filterProjects(filter) {
  return {
    type: actionTypes.FILTER_PROJECTS,
    filter
  }
}

export function createFolder(name, parent, stateFilter, retries = 1) {
  let parentId = parent

  if (parent === 'root') {
    parentId = null
  }

  return {
    type: actionTypes.CREATE_FOLDER,
    name,
    parentId,
    stateFilter,
    retries
  }
}

export function folderCreated(folder) {
  return {
    type: actionTypes.FOLDER_CREATED,
    folder
  }
}

export function createFolderError(error) {
  return {
    type: actionTypes.CREATE_FOLDER_ERROR,
    error
  }
}

export function moveProject(projectId, parentId, oldParentId, retries = 1) {
  return {
    type: actionTypes.MOVE_PROJECT,
    projectId,
    parentId,
    oldParentId,
    retries
  }
}

export function projectMoved(projectId, oldParentId, parentId) {
  return {
    type: actionTypes.PROJECT_MOVED,
    projectId,
    parentId,
    oldParentId
  }
}

export function moveProjectError(error) {
  return {
    type: actionTypes.MOVE_PROJECT_ERROR,
    error
  }
}

export function clearMoveProject() {
  return {
    type: actionTypes.CLEAR_MOVE_PROJECT
  }
}

export function moveFolder(folderId, parentId, oldParentId, retries = 1) {
  return {
    type: actionTypes.MOVE_FOLDER,
    folderId,
    parentId,
    oldParentId,
    retries
  }
}

export function folderMoved(folderId, oldParentId, parentId) {
  return {
    type: actionTypes.FOLDER_MOVED,
    folderId,
    oldParentId,
    parentId
  }
}

export function moveFolderError(error) {
  return {
    type: actionTypes.MOVE_FOLDER_ERROR,
    error
  }
}

export function clearMoveFolder() {
  return {
    type: actionTypes.CLEAR_MOVE_FOLDER
  }
}

export function selectFolderBatch(folder) {
  return {
    type: actionTypes.SELECT_FOLDER_BATCH,
    folder
  }
}
export function deselectFolderBatch(folder) {
  return {
    type: actionTypes.DESELECT_FOLDER_BATCH,
    folder
  }
}
export function deselectAllFolderBatch() {
  return {
    type: actionTypes.DESELECT_ALL_FOLDER_BATCH
  }
}
export function moveItemsBatch(to, retries = 1) {
  return {
    type: actionTypes.MOVE_ITEMS_BATCH,
    to,
    retries
  }
}
export function clearMoveItemsBatch() {
  return {
    type: actionTypes.CLEAR_MOVE_ITEMS_BATCH
  }
}
export function itemsBatchMoved() {
  return {
    type: actionTypes.ITEMS_BATCH_MOVED
  }
}
export function moveItemsBatchError(error) {
  return {
    type: actionTypes.MOVE_ITEMS_BATCH_ERROR,
    error
  }
}

export function selectProjectBatch(project) {
  return {
    type: actionTypes.SELECT_PROJECT_BATCH,
    project
  }
}

export function deselectProjectBatch(project) {
  return {
    type: actionTypes.DESELECT_PROJECT_BATCH,
    project
  }
}
export function deselectAllProjectBatch() {
  return {
    type: actionTypes.DESELECT_ALL_PROJECT_BATCH
  }
}

export function updateFolder(folderId, options, parentId, retries = 1) {
  return {
    type: actionTypes.UPDATE_FOLDER,
    folderId,
    options,
    parentId,
    retries
  }
}
export function updateFolderDone(folderId, parentId, options) {
  return {
    type: actionTypes.UPDATE_FOLDER_DONE,
    folderId,
    parentId,
    options
  }
}
export function updateFolderError(error) {
  return {
    type: actionTypes.UPDATE_FOLDER_ERROR,
    error
  }
}

export function updateProject(projectId, options, parentId, retries = 1) {
  return {
    type: actionTypes.UPDATE_PROJECT,
    projectId,
    options,
    parentId,
    retries
  }
}
export function updateProjectDone(project, parentId) {
  return {
    type: actionTypes.UPDATE_PROJECT_DONE,
    project,
    parentId
  }
}
export function updateProjectError(error) {
  return {
    type: actionTypes.UPDATE_PROJECT_ERROR,
    error
  }
}

export function updateProjectBatch(options, retries = 1) {
  return {
    type: actionTypes.UPDATE_PROJECT_BATCH,
    options,
    retries
  }
}
export function updateProjectBatchDone(options) {
  return {
    type: actionTypes.UPDATE_PROJECT_BATCH_DONE,
    options
  }
}
export function updateProjectBatchError(error) {
  return {
    type: actionTypes.UPDATE_PROJECT_BATCH_ERROR,
    error
  }
}

export function checkCanCreateProjects(retries = 1) {
  return {
    type: actionTypes.CHECK_CAN_CREATE_PROJECT,
    retries
  }
}
export function checkCanCreateProjectsDone(canCreateProject) {
  return {
    type: actionTypes.CHECK_CAN_CREATE_PROJECT_DONE,
    canCreateProject
  }
}
export function checkCanCreateProjectsError(error) {
  return {
    type: actionTypes.CHECK_CAN_CREATE_PROJECT_ERROR,
    error
  }
}

export function clearCreateFolder() {
  return {
    type: actionTypes.CLEAR_CREATE_FOLDER
  }
}

export function userLeaveProject(projectId, retries = 1) {
  return {
    type: actionTypes.USER_LEAVE_PROJECT,
    projectId,
    retries
  }
}
export function userLeaveProjectDone(projectId) {
  return {
    type: actionTypes.USER_LEAVE_PROJECT_DONE,
    projectId
  }
}
export function userLeaveProjectError(error) {
  return {
    type: actionTypes.USER_LEAVE_PROJECT_ERROR,
    error
  }
}

export function deleteEmptyFolder(folderId, parentId, retries = 1) {
  return {
    type: actionTypes.DELETE_EMPTY_FOLDER,
    folderId,
    parentId,
    retries
  }
}
export function deleteEmptyFolderDone(folderId, parentId) {
  return {
    type: actionTypes.DELETE_EMPTY_FOLDER_DONE,
    folderId,
    parentId
  }
}

export function deleteEmptyFolderError(error) {
  return {
    type: actionTypes.DELETE_EMPTY_FOLDER_ERROR,
    error
  }
}

export function clearProjects() {
  return {
    type: actionTypes.CLEAR_PROJECTS
  }
}
export function getRootProjects(
  page,
  sort,
  stateFilter,
  search,
  refresh,
  sortDirection,
  retries = 1
) {
  return {
    type: actionTypes.GET_ROOT_PROJECTS,
    page,
    sort,
    stateFilter,
    search,
    refresh,
    sortDirection,
    retries
  }
}
export function getRootProjectsDone(projects, timestamp, cached) {
  return {
    type: actionTypes.GET_ROOT_PROJECTS_DONE,
    projects,
    cached,
    timestamp
  }
}
export function getRootProjectsError(error) {
  return {
    type: actionTypes.GET_ROOT_PROJECTS_ERROR,
    error
  }
}
export function getFolderProjects(folderId, page, retries = 1) {
  return {
    type: actionTypes.GET_FOLDER_PROJECTS,
    folderId,
    page,
    retries
  }
}
export function getFolderProjectsDone(
  folderId,
  projects,
  isRoot,
  timestamp,
  cached
) {
  return {
    type: actionTypes.GET_FOLDER_PROJECTS_DONE,
    folderId,
    projects,
    isRoot,
    cached,
    timestamp
  }
}
export function getFolderProjectsError(folderId, error) {
  return {
    type: actionTypes.GET_FOLDER_PROJECTS_ERROR,
    error,
    folderId
  }
}
export function openProjectFolder(folderId) {
  return {
    type: actionTypes.OPEN_PROJECT_FOLDER,
    folderId
  }
}
export function closeProjectFolder(folderId) {
  return {
    type: actionTypes.CLOSE_PROJECT_FOLDER,
    folderId
  }
}
export function setGetProjectTreeCancelToken(cancelToken) {
  return {
    type: actionTypes.SET_GET_PROJECT_TREE_CANCEL_TOKEN,
    cancelToken
  }
}

export function getProjectPath(projectId, retries) {
  return {
    type: actionTypes.GET_PROJECT_PATH,
    projectId,
    retries
  }
}
export function getProjectPathDone(projectId, projectFolder) {
  return {
    type: actionTypes.GET_PROJECT_PATH_DONE,
    projectId,
    projectFolder
  }
}
export function getProjectPathError(projectId, error) {
  return {
    type: actionTypes.GET_PROJECT_PATH_ERROR,
    error,
    projectId
  }
}
