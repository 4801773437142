import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { H1 } from './styled-components'

const PageTitle = ({
  className,
  wrapLine,
  maxWidth,
  dataTestid,
  title,
  values
}) => {
  return (
    <H1
      className={className}
      wrapLine={wrapLine}
      maxWidth={maxWidth}
      data-testid={dataTestid}
    >
      <FormattedMessage {...title} values={values} />
    </H1>
  )
}

PageTitle.propTypes = {
  title: PropTypes.object.isRequired,
  values: PropTypes.object,
  maxWidth: PropTypes.number,
  wrapLine: PropTypes.bool,
  dataTestid: PropTypes.string,
  className: PropTypes.string
}

export default PageTitle
