import styled from 'styled-components'
import { ButtonPrimary } from '../Button'
import colorsOptions from '../stories/fundamentals/colors-styles/constants'
import {
  sizing,
  weights,
} from '../stories/fundamentals/text-styles/text-styles'

export const Onboarding = styled.section`
  padding: 58px 58px 20px 58px;
`

export const Devices = styled.img`
  float: right;
  width: 300px;
  margin-left: 52px;
`

export const Heading = styled.h3`
  ${weights['text-700']};
  font-weight: bold;
  ${sizing['text-xl']};
  color: ${colorsOptions.greys['grey-60']};
`

export const Text = styled.p`
  color: ${colorsOptions.greys['grey-60']};
  ${sizing['text-l']};
`

export const MainText = styled(Text)`
  margin: 40px 0px;
`

export const SubText = styled(Text)`
  margin-top: 6px;
`

export const Button = styled(ButtonPrimary)`
  margin-bottom: 54px;
`

export const A = styled.a`
  color: ${colorsOptions.docuToolsOrange.orange};
`
