export const DEFAULT_LOCALE = 'en'
export const SHOW_POPUP_OVERLAY = 'app/App/SHOW_POPUP_OVERLAY'
export const HIDE_POPUP_OVERLAY = 'app/App/HIDE_POPUP_OVERLAY'
export const SHOW_MOBILE_POPUP_OVERLAY = 'app/App/SHOW_MOBILE_POPUP_OVERLAY'
export const HIDE_MOBILE_POPUP_OVERLAY = 'app/App/HIDE_MOBILE_POPUP_OVERLAY'
export const TOGGLE_MENU = 'app/App/TOGGLE_MENU'
export const CLOSE_MENU = 'app/App/CLOSE_MENU'
export const RESIZE = 'app/App/RESIZE'
export const SHOW_GALLERY = 'app/App/SHOW_GALLERY'
export const HIDE_GALLERY = 'app/App/HIDE_GALLERY'
export const SWITCH_GALLERY_INDEX = 'app/App/SWITCH_GALLERY_INDEX'
export const NEW_WEB_APP_VERSION_AVAILABLE =
  'app/App/NEW_WEB_APP_VERSION_AVAILABLE'
export const NEW_WEB_APP_VERSION_READY = 'app/App/NEW_WEB_APP_VERSION_READY'
export const NEW_WEB_APP_VERSION_REFRESH = 'app/App/NEW_WEB_APP_VERSION_REFRESH'
export const UPDATE_DYNAMIC_LOADED_SCRIPT =
  'app/App/UPDATE_DYNAMIC_LOADED_SCRIPT'
export const SAVE_FILE = 'app/App/SAVE_FILE'
export const SHOW_BOTTOM_BAR = 'app/App/SHOW_BOTTOM_BAR'
export const GET_BROWSER_INFO = 'app/App/GET_BROWSER_INFO'
