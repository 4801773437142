import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  ErrorDiv,
  Button,
  DetailsDiv,
  NetworkErrorPopup,
  CustomBtn,
  Retry
} from './styled-components'

const ErrorPopup = ({ onClosePopup, noDiv, error }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [popupClosed, setPopupClosed] = useState(false)

  const closePopup = useCallback(() => {
    if (onClosePopup) onClosePopup()
    setPopupClosed(true)
  }, [onClosePopup])

  let { name, message, response, onRetry } = error

  name = name || 'Error'
  message = typeof error === 'string' ? error : message

  const type =
    message === 'Network Error' ? 'network' : response ? 'response' : undefined

  if (popupClosed) return null

  const content = (
    <>
      {type === 'network' && (
        <NetworkErrorPopup>
          <FormattedMessage id="error_network" />
          <div className="footer">
            <CustomBtn onClick={() => location.reload()}>
              <FormattedMessage id="refreshPage" />
            </CustomBtn>
            <CustomBtn onClick={closePopup}>
              <FormattedMessage id="close" />
            </CustomBtn>
          </div>
        </NetworkErrorPopup>
      )}
      {type === 'response' && (
        <span>
          <h3>
            <FormattedMessage id="error_default" />
          </h3>
          <p>
            {!!response.data && !!response.data.error_description && (
              <span>{response.data.error_description}</span>
            )}
            {onRetry && (
              <Retry onClick={onRetry}>
                <span>&nbsp;</span>
                <FormattedMessage id="try_again" />
              </Retry>
            )}
          </p>
        </span>
      )}
      {!type && (
        <span>
          <h3>
            <FormattedMessage id="error_default" />
          </h3>
          <Button onClick={() => setShowDetails((prev) => !prev)}>
            <FormattedMessage id="error_defaultDetails" />
          </Button>
          {showDetails && (
            <DetailsDiv>
              <h4>{name}</h4>
              <p>{message}</p>
            </DetailsDiv>
          )}
        </span>
      )}
    </>
  )

  return noDiv ? content : <ErrorDiv>{content}</ErrorDiv>
}

ErrorPopup.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired
  ]),
  noDiv: PropTypes.bool,
  onClosePopup: PropTypes.func
}

export default ErrorPopup
