import PropTypes from 'prop-types'
import styled from 'styled-components'
import colorsOptions from '../stories/fundamentals/colors-styles/constants'

const Path = styled.polyline`
  fill: none;
  stroke: ${({ color }) => color};
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.7px;
`

export function Cross(props) {
  const {
    color = colorsOptions.greys['grey-100'],
    height = 40,
    width = 40,
  } = props

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      height={height}
      width={width}
    >
      <Path color={color} points="17.5 24.5 26.5 15.5" />
      <Path color={color} points="26.5 24.5 17.5 15.5" />
    </svg>
  )
}

Cross.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

const PaddingLessSvg = styled.svg`
  polyline {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1px;
    stroke: ${({ color }) => color};
  }

  &:hover {
    polyline {
      stroke: ${({ hoverColor }) => hoverColor};
    }
  }
`

export function PaddingLessCross(props) {
  const {
    color = colorsOptions.greys['grey-100'],
    hoverColor = colorsOptions.greys['grey-100'],
    height = 40,
    width = 40,
  } = props

  return (
    <PaddingLessSvg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 10"
      height={height}
      width={width}
      color={color}
      hoverColor={hoverColor}
    >
      <polyline points="0 10 10 0" />
      <polyline points="10 10 0 0" />
    </PaddingLessSvg>
  )
}

PaddingLessCross.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

/**
 * Check Icon in custom color
 * @component
 */
export function Check(props) {
  const {
    color = colorsOptions.greys['grey-100'],
    height = 40,
    width = 40,
  } = props

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      height={height}
      width={width}
    >
      <Path color={color} points="13.5 20.5 17.5 24.5 26.5 15.5" />
    </svg>
  )
}

Check.propTypes = {
  /** color of the check */
  color: PropTypes.string,
  /** height of the arrow surrounding box */
  height: PropTypes.number,
  /** width of the arrow surrounding box */
  width: PropTypes.number,
}

export default Check
