import { number, string } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/constants'

const ListSvg = styled.svg`
  width: ${prop('size', 17)}px;
  height: ${prop('size', 17)}px;
  .a,
  .b {
    fill: none;
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .b {
    stroke-linecap: round;
  }
`

function ListIcon({ color, size }) {
  return (
    <ListSvg
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      color={color}
      viewBox="0 0 17 17"
    >
      <g transform="translate(0.5 0.5)">
        <rect className="a" width="16" height="16" rx="2" />
        <line className="b" x1="4" transform="translate(3 7)" />
        <line className="b" x1="4" transform="translate(9 7)" />
        <line className="b" x1="4" transform="translate(3 5)" />
        <line className="b" x1="4" transform="translate(9 5)" />
        <line className="b" x1="4" transform="translate(3 11)" />
        <line className="b" x1="4" transform="translate(9 11)" />
        <line className="b" x1="4" transform="translate(3 9)" />
        <line className="b" x1="4" transform="translate(9 9)" />
      </g>
    </ListSvg>
  )
}

ListIcon.propTypes = {
  color: string,
  size: number,
}

export default ListIcon
