import { lazy, Suspense } from 'react'
import { AsyncLoaderComponent } from '../../components/async-loader/async-loader-component'

export const DELETE_PROJECT_PICTURE =
  'app/ProjectSettings/DELETE_PROJECT_PICTURE'
export const UPLOAD_PROJECT_PICTURE =
  'app/ProjectSettings/UPLOAD_PROJECT_PICTURE'
export const PROJECT_PICTURE_UPLOADED =
  'app/ProjectSettings/PROJECT_PICTURE_UPLOADED'
export const PROJECT_PICTURE_UPLOAD_ERROR =
  'app/ProjectSettings/PROJECT_PICTURE_UPLOAD_ERROR'

export const GET_PROJECT_PICTURE = 'app/ProjectSettings/GET_PROJECT_PICTURE'
export const SET_PROJECT_PICTURE = 'app/ProjectSettings/SET_PROJECT_PICTURE'
export const GET_PROJECT_PICTURE_ERROR =
  'app/ProjectSettings/GET_PROJECT_PICTURE_ERROR'

export const CLEAR_PROJECT_DETAILS = 'app/ProjectSettings/CLEAR_PROJECT_DETAILS'
export const GET_PROJECT_DETAILS = 'app/ProjectSettings/GET_PROJECT_DETAILS'
export const SET_PROJECT_DETAILS = 'app/ProjectSettings/SET_PROJECT_DETAILS'
export const GET_PROJECT_DETAILS_ERROR =
  'app/ProjectSettings/GET_PROJECT_DETAILS_ERROR'

export const SAVE_PROJECT_DETAILS = 'app/ProjectSettings/SAVE_PROJECT_DETAILS'
export const PROJECT_DETAILS_SAVED = 'app/ProjectSettings/PROJECT_DETAILS_SAVED'
export const SAVE_PROJECT_DETAILS_ERROR =
  'app/ProjectSettings/SAVE_PROJECT_DETAILS_ERROR'

export const GET_PIN_CATEGORIES = 'app/ProjectSettings/GET_PIN_CATEGORIES'
export const SET_PIN_CATEGORIES = 'app/ProjectSettings/SET_PIN_CATEGORIES'
export const GET_PIN_CATEGORIES_ERROR =
  'app/ProjectSettings/GET_PIN_CATEGORIES_ERROR'

export const CREATE_AND_UPDATE_PIN_CATEGORIES =
  'app/ProjectSettings/CREATE_AND_UPDATE_PIN_CATEGORIES'
export const PIN_CATEGORY_CREATED = 'app/ProjectSettings/PIN_CATEGORY_CREATED'
export const PIN_CATEGORY_UPDATED = 'app/ProjectSettings/PIN_CATEGORY_UPDATED'
export const PIN_CATEGORIES_CREATED_AND_UPDATED =
  'app/ProjectSettings/PIN_CATEGORIES_CREATED_AND_UPDATED'
export const CREATE_AND_UPDATE_PIN_CATEGORIES_ERROR =
  'app/ProjectSettings/CREATE_AND_UPDATE_PIN_CATEGORIES_ERROR'
export const CLEAR_PIN_CATEGORIES = 'app/ProjectSettings/CLEAR_PIN_CATEGORIES'

export const GET_TEMPLATES_OF_PROJECT =
  'app/ProjectSettings/GET_TEMPLATES_OF_PROJECT'
export const GET_TEMPLATES_OF_PROJECT_DONE =
  'app/ProjectSettings/GET_TEMPLATES_OF_PROJECT_DONE'
export const GET_TEMPLATES_OF_PROJECT_ERROR =
  'app/ProjectSettings/GET_TEMPLATES_OF_PROJECT_ERROR'

export const GET_FIELDS = 'app/ProjectSettings/GET_FIELDS'
export const GET_FIELDS_DONE = 'app/ProjectSettings/GET_FIELDS_DONE'
export const GET_FIELDS_ERROR = 'app/ProjectSettings/GET_FIELDS_ERROR'

export const UPDATE_PIN_FIELD = 'app/ProjectSettings/UPDATE_PIN_FIELD'
export const UPDATE_PIN_FIELD_DONE = 'app/ProjectSettings/UPDATE_PIN_FIELD_DONE'
export const UPDATE_PIN_FIELD_ERROR =
  'app/ProjectSettings/UPDATE_PIN_FIELD_ERROR'

export const CREATE_PIN_FIELD = 'app/ProjectSettings/CREATE_PIN_FIELD'
export const CREATE_PIN_FIELD_DONE = 'app/ProjectSettings/CREATE_PIN_FIELD_DONE'
export const CLEAR_CREATED_FIELD = 'app/ProjectSettings/CLEAR_CREATED_FIELD'
export const CREATE_PIN_FIELD_ERROR =
  'app/ProjectSettings/CREATE_PIN_FIELD_ERROR'

export const REPORT_LOGO = 'REPORT_LOGO'

export const validationErrors = {
  TOO_SHORT: 'app/ProjectSettings/TOO_SHORT',
  TOO_LONG: 'app/ProjectSettings/TOO_LONG',
  REQUIRED: 'app/ProjectSettings/REQUIRED',
  BEFORE_START: 'app/ProjectSettings/BEFORE_START',

  ALREADY_IN_USE: 'app/ProjectSettings/ALREADY_IN_USE',
  EMPTY: 'app/ProjectSettings/EMPTY'
}

export const GET_ASSIGNABLE_TEMPLATES = 'app/ProjectSettings/GET_ASSIGNABLE_TEMPLATES'
export const GET_ASSIGNABLE_TEMPLATES_DONE = 'app/ProjectSettings/GET_ASSIGNABLE_TEMPLATES_DONE'
export const GET_ASSIGNABLE_TEMPLATES_ERROR = 'app/ProjectSettings/GET_ASSIGNABLE_TEMPLATES_ERROR'

/*
 ** Reducer actions
 */

export const SET_ACTIVE_MODULE = 0
export const SET_MODULE_CHANGED = 1
export const SET_READY_TO_SAVE = 2
export const SET_LOADING_STATE = 3
export const SET_SAVING_STATE = 4
export const SET_SAVING_METHOD = 5
export const SET_MODULE_INFO = 6
export const UPDATE_FORM = 7
export const SET_FORM_ERROR = 8
export const CLEAR_STATE = 9
export const FLUSH_READY_TO_SAVE = 10

/*
 ** Modules base info
 */

const ProjectDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectDetails" */ './components/ProjectDetails'
    )
)
const Address = lazy(
  () => import(/* webpackChunkName: "ProjectAddress" */ './components/Address')
)
const ReportTemplates = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectReportTemplates" */ './components/ReportTemplates/index'
    )
)
const Stakeholders = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectStakeholders" */ './components/Stakeholders'
    )
)
const PinCategories = lazy(
  () =>
    import(
      /* webpackChunkName: "PinCategories" */ './components/PinCategories'
    )
)
const PinDesign = lazy(
  () =>
    import(/* webpackChunkName: "PinDesign" */ './components/PinDesign')
)

export const SLUGS = {
  DETAILS: 'details',
  ADDRESS: 'address',
  STAKEHOLDERS: 'stakeholders',
  DESIGN: 'design',
  CATEGORIES: 'categories',
  TEMPLATES: 'templates'
}

export const MODULE_BASE_INFO = {
  details: {
    slug: SLUGS.DETAILS,
    title: 'projectSettings_details',
    groupedSave: true,
    Element: (props) => (
      <Suspense fallback={AsyncLoaderComponent}>
        <ProjectDetails {...props} />
      </Suspense>
    )
  },
  address: {
    slug: SLUGS.ADDRESS,
    title: 'projectSettings_address',
    groupedSave: true,
    Element: (props) => (
      <Suspense fallback={AsyncLoaderComponent}>
        <Address {...props} />
      </Suspense>
    )
  },
  stakeholders: {
    slug: SLUGS.STAKEHOLDERS,
    title: 'stakeholders',
    groupedSave: true,
    Element: (props) => (
      <Suspense fallback={AsyncLoaderComponent}>
        <Stakeholders {...props} />
      </Suspense>
    )
  },
  design: {
    slug: SLUGS.DESIGN,
    title: 'projectSettings_pinDesign',
    featureSlug: 'PIN_FIELDS',
    groupedSave: true,
    Element: (props) => (
      <Suspense fallback={AsyncLoaderComponent}>
        <PinDesign {...props} />
      </Suspense>
    )
  },
  categories: {
    slug: SLUGS.CATEGORIES,
    title: 'projectSettings_pinCategories',
    Element: (props) => (
      <Suspense fallback={AsyncLoaderComponent}>
        <PinCategories {...props} />
      </Suspense>
    )
  },
  templates: {
    slug: SLUGS.TEMPLATES,
    title: 'reports_reportTemplates',
    Element: (props) => (
      <Suspense fallback={AsyncLoaderComponent}>
        <ReportTemplates {...props} />
      </Suspense>
    )
  }
}
