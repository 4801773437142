import styled from 'styled-components'
import BButton from '../Button'
import colorsOptions from '../stories/fundamentals/colors-styles/constants'
import {
  sizing,
  weights
} from '../stories/fundamentals/text-styles/text-styles'

export const ErrorDiv = styled.div`
  margin: 10px;
`

export const CustomBtn = styled(BButton)`
  padding: 8px 26px;
`
export const NetworkErrorPopup = styled.div`
  position: fixed;
  max-width: 400px;
  width: 100%;
  @media (max-width: 400px) {
    width: 95%;
  }
  height: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${colorsOptions.greys.white};
  z-index: 9999;
  padding: 20px;
  border: 1px solid ${colorsOptions.greys['grey-20']};
  box-shadow: 0px 1px 5px 0px rgba(20, 26, 33, 0.2);
  ${weights['text-500']};
  .footer {
    position: absolute;
    bottom: 0;
    right: 0;

    button {
      margin: 10px;
    }
  }
`

export const Retry = styled.span``

export const ErrorMessage = styled.h3`
  color: ${colorsOptions.secondaryColors.orange.normal};
  ${sizing['text-l']};
  ${weights['text-600']};
`

export const Button = styled.button`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const DetailsDiv = styled.div`
  padding: 5px;
  border: 1px solid ${colorsOptions.greys['grey-100']};
`
