import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'

import PopupNew from '../popup-new'
import { popupSize } from '../popup-new/constants'
import devicesImage from './images/Devices.svg'
import {
  Onboarding,
  Devices,
  Heading,
  MainText,
  SubText,
  Button,
  A
} from './styled-components'
import {
  makeSelectAccessToken,
  makeSelectOrganisationName
} from '../../containers/Authentication/selectors'
import { makeSelectLocale } from '../../containers/LanguageProvider/selectors'
import { getZendeskLinks } from '../../utils/getZendeskLinks'
import { useSelector } from 'react-redux'
import { useBrowserStore } from '../../zustand/browser-store/use-browser-store'


const LINK_TO_VIDEO = 'https://www.youtube.com/watch?v=jzVu7gNeIlQ'

export const OnboardingPopup = ({ closePopup }) => {
  const { formatMessage } = useIntl()
  const { isMobile } = useBrowserStore()

  const accessToken = useSelector(makeSelectAccessToken())
  const locale = useSelector(makeSelectLocale())
  const organisationName = useSelector(makeSelectOrganisationName())

  const { zendeskLandingPageLink } = useMemo(() => {
    return getZendeskLinks(accessToken, locale, organisationName)
  }, [accessToken, locale, organisationName])

  const trainingAndSupport = (
    <A href={zendeskLandingPageLink} target="_blank">
      <FormattedMessage id="onboarding_popup_footer_link_text" />
    </A>
  )

  if (isMobile) {
    return null
  }

  return (
    <PopupNew
      size={popupSize.MEDIUM}
      closePopup={closePopup}
      width={802}
      rounded
      showCloseTopRight
      noFooter
      dataTestId="OnboardingPopup"
    >
      <Onboarding>
        <Devices
          src={devicesImage}
          alt={formatMessage({ id: 'onboarding_popup_img_alt' })}
        />
        <Heading>
          <FormattedMessage id="onboarding_popup_title" />
        </Heading>
        <MainText>
          <FormattedMessage id="onboarding_popup_text" />
        </MainText>
        <Button as="a" height="36px" href={LINK_TO_VIDEO} target="_blank">
          <FormattedMessage id="onboarding_popup_button" />
        </Button>
        <FormattedMessage tagName="h4" id="onboarding_popup_footer_title" />
        <SubText>
          <FormattedMessage
            id="onboarding_popup_footer_text"
            values={{ trainingAndSupport }}
          />
        </SubText>
      </Onboarding>
    </PopupNew>
  )
}

OnboardingPopup.propTypes = {
  closePopup: PropTypes.func
}

export default OnboardingPopup
