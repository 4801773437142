import { fromJS } from 'immutable'
import { LOGOUT } from '../Authentication/constants'
import * as actionTypes from './constants'

const initialState = fromJS({
  userData: {},
})

function registerReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_USER_DATA:
      return state.set('userData', action.userData)
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default registerReducer
