import { sizing } from '../../fundamentals/text-styles/text-styles'
import {
  highlightedVariant,
  minimalVariant,
  strongVariant,
  subtleVariant
} from './styled-components'

const badgeSizeOptions = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  'EXTRA-LARGE': 'extra-large'
}

const badgeStyleOptions = {
  [badgeSizeOptions.SMALL]: {
    iconSize: '10px',
    padding: '4px 4px 4px 4px',
    gap: '2px',
    maxHeight: '18px',
    ...sizing['text-xs']
  },
  [badgeSizeOptions.MEDIUM]: {
    iconSize: '12px',
    padding: '4px 8px 4px 8px',
    gap: '4px',
    maxHeight: '24px',
    ...sizing['text-s']
  },
  [badgeSizeOptions.LARGE]: {
    iconSize: '14px',
    padding: '4px 12px 4px 12px',
    gap: '6px',
    maxHeight: '28px',
    ...sizing['text-r']
  },
  [badgeSizeOptions['EXTRA-LARGE']]: {
    iconSize: '16px',
    padding: '4px 16px 4px 16px',
    gap: '8px',
    maxHeight: '32px',
    ...sizing['text-l']
  }
}

const badgesVariantOptions = {
  MINIMAL: 'minimal',
  SUBTLE: 'subtle',
  STRONG: 'strong',
  HIGHLIGHTED: 'highlighted'
}

const badgeComponentVariantOptions = {
  [badgesVariantOptions.MINIMAL]: minimalVariant,
  [badgesVariantOptions.SUBTLE]: subtleVariant,
  [badgesVariantOptions.STRONG]: strongVariant,
  [badgesVariantOptions.HIGHLIGHTED]: highlightedVariant
}

const badgeColorOptions = [
  'yellow',
  'orange',
  'red',
  'pink',
  'purple',
  'violet',
  'darkblue',
  'blue',
  'turquoise',
  'petrol',
  'darkgreen',
  'green',
  'ochre',
  'muesli',
  'darkbrown',
  'docu-tools-dark',
  'docu-tools-orange',
  'disabled'
]

export {
  badgeSizeOptions,
  badgeStyleOptions,
  badgesVariantOptions,
  badgeComponentVariantOptions,
  badgeColorOptions
}
