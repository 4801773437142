import { detect } from 'detect-browser'
import { create } from 'zustand'
import { mobileOSList, tabletUserAgent } from './constants'

export const useBrowserStore = create((set, get) => ({
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  setWindowSize: (width, height) =>
    set({ windowWidth: width, windowHeight: height }),

  menuOpen: window.innerWidth > 1024,
  setMenuOpen: (isOpen) => set({ menuOpen: isOpen }),

  showBottomBar: true,
  setShowBottomBar: (isOpen) => set({ showBottomBar: isOpen }),

  browserInfo: detect(),

  showPopupOverlay: false,
  setShowPopupOverlay: (isOpen) => set({ showPopupOverlay: isOpen }),

  showMobilePopupOverlay: false,
  setShowMobilePopupOverlay: (isOpen) =>
    set({ showMobilePopupOverlay: isOpen }),

  // returns a boolean
  get isMobile() {
    const { windowWidth, browserInfo } = get()

    const isMobileOS = mobileOSList.includes(browserInfo?.os)

    const isTablet = tabletUserAgent.some((uA) =>
      window.navigator.userAgent.includes(uA)
    )
    return windowWidth < 900 && isMobileOS && !isTablet
  }
}))
