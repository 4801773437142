import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { ifProp, prop, withProp } from 'styled-tools'
import Button from '../Button'
import Input from '../input'
import { MainFooter } from '../popup-new/styled-components'
import colorsOptions from '../stories/fundamentals/colors-styles/constants'
import {
  sizing,
  weights
} from '../stories/fundamentals/text-styles/text-styles'

export const PopupDefaultContainer = styled.div`
  position: fixed;
  background: ${colorsOptions.greys.white};
  top: 50%;
  left: 50%;
  z-index: 9999;
  padding: 0;
  max-width: 391.5px;
  width: 100%;
  transform: translate(-50%, -49%);
  border: 1px solid ${colorsOptions.greys['grey-30']};
  @media (max-width: 767px) {
    width: 89%;
  }
`

export const PopupLiteContainer = styled.div`
  position: absolute;
  background: ${colorsOptions.greys.white};
  z-index: 9999;
  padding: 0;
  border: 1px solid ${colorsOptions.greys['grey-30']};
`

export const H1 = styled.h1`
  background: ${colorsOptions.greys['grey-10']};
  ${sizing['text-l']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  margin: 0;
  padding: 16px 10px 16px 16px;
  border-bottom: 1px solid ${colorsOptions.greys['grey-20']};
`

export const PopupInside = styled.div`
  padding: 15px 16px 15px;
`
export const PopupInsideNew = styled.div`
  padding: 15px 16px 0;
`

export const InfoText = styled.div`
  text-align: center;
  padding: 15px;
`

export const InputName = styled(Input)``

export const NameLable = styled.span`
  ${sizing['text-r']};
  ${weights['text-600']};
  display: block;
  color: ${colorsOptions.greys['grey-50']};
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

export const SelectWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 50px;
`

export const NameLableSmall = styled.span`
  ${weights['text-600']};
  display: block;
  color: ${colorsOptions.greys['grey-50']};
  margin-bottom: 3px;
  ${sizing['text-r']};
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

export const ButtonBlock = styled.div`
  margin-top: 10px;
  text-align: right;
  clear: both;
`
export const TitleText = styled.div`
  ${weights['text-600']};
  display: inline-block;
`

export const ButtonBlockTwo = styled.div`
  margin-top: 10px;
  text-align: center;
  clear: both;
`

export const SaveOptionsWrapper = styled.div`
  margin-top: 22px;
`

export const SaveOptions = styled(Button)`
  padding: 10px 40px;
  display: inline-block;
  color: ${colorsOptions.greys['grey-100']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 40px;
  border: 1px solid ${colorsOptions.greys['grey-30']};

  background: ${colorsOptions.greys.white};
  ${weights['text-600']};
  &:hover {
    background: ${colorsOptions.greys['grey-20']};
    color: ${colorsOptions.greys['grey-60']};
    border-color: ${colorsOptions.greys['grey-30']};
    cursor: pointer;
  }
  &:disabled {
    background: none !important;
    opacity: 0.6;
    color: ${colorsOptions.greys['grey-50']};
    border: 1px solid ${colorsOptions.greys['grey-30']};
    cursor: not-allowed;
  }
  @media (max-width: 767px) {
    padding: 7px 4px;
    display: inline-block;
    ${sizing['text-r']};
  }
`

export const CancelOptions = styled(Button)`
  padding: 10px 40px;
  display: inline-block;
  ${({ isOkOnly }) => (isOkOnly ? 'margin-left:50%;' : '')}
  color:${colorsOptions.greys['grey-60']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 40px;
  border: 1px solid ${colorsOptions.greys['grey-30']};

  background: ${colorsOptions.greys.white};
  ${weights['text-600']};
  margin-right: 4%;
  &:hover {
    background: ${colorsOptions.greys['grey-20']};
    color: ${colorsOptions.greys['grey-60']};
    border-color: ${colorsOptions.greys['grey-30']};
    cursor: pointer;
  }
  &.nomargin {
    margin-right: 0;
  }
  &:disabled {
    background: none !important;
    opacity: 0.6;
    color: ${colorsOptions.greys['grey-50']};
    border: 1px solid ${colorsOptions.greys['grey-30']};
    cursor: not-allowed;
  }
  @media (max-width: 767px) {
    padding: 7px 4px;
    display: inline-block;
    ${sizing['text-r']};
  }
`
export const WarningCancelOptions = styled(CancelOptions)`
  &:hover {
    background: ${colorsOptions.greys['grey-20']};
    color: ${colorsOptions.secondaryColors.red.strong};
    border-color: ${colorsOptions.secondaryColors.red.normal};
  }
`

export const CloseOptions = styled(Button)`
  padding: 10px 59px;
`

export const CenterText = styled.div`
  text-align: center;
`

export const PlanNameBlock = styled.div`
  margin-top: 15px;
`
export const ValidationError = styled.span`
  color: ${colorsOptions.secondaryColors.orange.strong};
  ${sizing['text-r']};
  ${weights['text-600']};
  @media (max-width: 1024px) {
    ${sizing['text-s']};
  }
`

export const ControlFields = styled.div`
  margin-bottom: 15px;
`

export const PopupBody = styled.div`
  padding: 15px 16px 15px;
`

export const PopupFooter = styled.div`
  padding: 15px 16px 15px;
  ${ifProp(
    'alignRight',
    `
    display: flex;
    justify-content: flex-end;`
  )}
`

export const selectDropDownStyle = {
  control: (base) => ({
    ...base,
    height: '25px',
    minHeight: '25px',
    border: 'none',
    borderBottom: `1px solid ${colorsOptions.greys['grey-50']}`
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: 0
  }),
  singleValue: (base) => ({
    ...base,
    ...weights['text-600']
  })
}

export const TitleBar = styled.div`
  background: ${colorsOptions.greys['grey-10']};
  padding: 16px 10px 16px 16px;
  border-bottom: 1px solid ${colorsOptions.greys['grey-40']};
  span {
    ${weights['text-600']};
    color: ${colorsOptions.greys['grey-100']};
    ${sizing['text-l']};
  }
`

export const CloseBtn = styled.button`
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border-radius: 26px;
  height: 25px;
  width: 25px;
  overflow: hidden;
  float: right;
  text-align: center;
  margin-top: -3px;
`

export const SmallPopup = styled(PopupDefaultContainer)`
  ${ifProp(
    'left',
    css`
      left: ${prop('left')};
    `
  )}
  max-width: 400px;
  width: 100%;
  z-index: 99999;
  @media (max-width: 450px) {
    width: 95%;
  }
  box-shadow: 0 1px 5px 0 rgba(20, 26, 33, 0.2);
`

export const SubHeading = styled.div`
  ${sizing['text-l']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  margin-bottom: 15px;
`

export const FormLabel = styled.div`
  ${sizing['text-l']};
  ${weights['text-500']};
  margin-bottom: 6px;
  color: ${colorsOptions.greys['grey-60']};
`

export const CloseButton = styled.button`
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border-radius: 26px;
  height: 25px;
  width: 25px;
  overflow: hidden;
  float: right;
  text-align: center;
  margin-top: 3px;
`
export const CloseLink = styled(Link)`
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border-radius: 26px;
  height: 25px;
  width: 25px;
  overflow: hidden;
  float: right;
  text-align: center;
  margin-top: 3px;
`

export const PopUpSectionTitle = styled.div``

export const PopUpSection = styled.div``

const MODAL_DIMENSIONS = {
  SMALL: css`
    width: 35%;
    height: auto;
    min-height: 400px;
    @media (max-width: 767px) {
      width: 95%;
    }
  `,
  MID: css`
    width: 50%;
    height: 75%;
  `,
  LARGE: css`
    width: 75%;
    height: 75%;
  `
}

export const ModalWindow = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  background: ${prop('background', colorsOptions.greys['grey-10'])};
  display: flex;
  flex-direction: column;
  > div > ul > li {
    flex: 1 1 160px;
  }
  ${withProp(
    ['size'],
    (size) => MODAL_DIMENSIONS[size] ?? MODAL_DIMENSIONS.MID
  )}
`

export const ModalBody = styled.div`
  padding: 16px;
  width: 100%;
  height: 80%;
  flex: 1 1 auto;
`

export const ModalFooter = styled(MainFooter)`
  height: auto;
  margin-top: auto;
`

export const ContentGroup = styled.div`
  background: ${colorsOptions.greys.white};
  border: 1px solid ${colorsOptions.greys['grey-30']};
  border-radius: 8px;
  padding: 8px;
`
