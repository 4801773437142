import { bool, func, string } from 'prop-types'
import FloatingLabel from '../form-fields/components/text-area-with-floating-label'
import { DefaultInput } from './styled-components'

const Input = ({ inputType, label, labelVisible, labelColor, ...props }) => {
  const TypeComponent = inputType ?? DefaultInput

  if (label) {
    return (
      <div>
        <FloatingLabel visible={labelVisible} color={labelColor}>
          {label}
        </FloatingLabel>
        <TypeComponent {...props} />
      </div>
    )
  }
  return <TypeComponent {...props} />
}

Input.propTypes = {
  inputType: func,
  label: string,
  labelVisible: bool,
  labelColor: string
}

export default Input
