import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Portal } from '../portal'
import Page from '../page'
import {
  Loader,
  LoaderText,
  LoaderWrapper
} from '../page-loader/styled-components'
import {
  Body,
  CenterText,
  CloseLink,
  ContentText,
  LeftWrapper,
  MainFooter,
  Popup,
  PopupOverlay,
  PopupWrapper,
  RightWrapper,
  TitleBar
} from './styled-components'
import { Cross } from '../Icons/Check'
import PopupNewActionButton from './components/popup-new-action-button'
import { loadingType as loadType, popupSize } from './constants'
import LoadingDots from '../loading-dots'
import { getHeight } from '../../utils/popupHeight'
import Arrow, { directions } from '../Icons/Arrow'
import colorsOptions from '../stories/fundamentals/colors-styles/constants'
import { useBrowserStore } from '../../zustand/browser-store/use-browser-store'

const PopupNew = ({
  bodyMaxHeight,
  autoHeight,
  closePopup,
  title,
  contentText,
  leftButtons,
  rightButtons,
  children,
  showCloseTopRight,
  showArrowDownTopRight,
  size = popupSize.SMALL,
  closeLink,
  loading,
  loadingText,
  popupKeypress,
  loadingType,
  noFooter,
  overPopup,
  width,
  thicker,
  showFooterBorder,
  loadingPopupHeight,
  customBodyStyle,
  centralizeButtons,
  footerBgColor,
  bodyBgColor,
  rounded,
  dataTestId,
  scrollBarRef,
  bodyPadding,
  trueCenter
}) => {
  const { isMobile, windowHeight } = useBrowserStore()

  const autoHeightMax =
    (bodyMaxHeight ?? size === popupSize.X_LARGE) ? 900 : 500

  const { scrollableStyle, uploadingPopupHeight } = getHeight(
    windowHeight,
    isMobile,
    size
  )
  const sizeMediumLarge = [
    popupSize.X_LARGE,
    popupSize.LARGE,
    popupSize.MEDIUM
  ].includes(size)

  const newAutoHeight = autoHeight || !sizeMediumLarge

  const handleClosePopup = (e) => {
    e.preventDefault()
    closePopup()
  }

  const handleEscKeyPress = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        handleClosePopup(event)
      }
    },
    [handleClosePopup]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleEscKeyPress)
    return () => {
      document.removeEventListener('keydown', handleEscKeyPress)
    }
  }, [handleEscKeyPress])

  return (
    <Portal node={document.getElementById('root')}>
      <>
        <PopupOverlay overPopup={overPopup} />
        <PopupWrapper trueCenter={trueCenter} overPopup={overPopup} size={size}>
          <Popup
            data-testid={dataTestId}
            thicker={thicker}
            onKeyPress={popupKeypress}
            size={size}
            width={width}
            rounded={rounded}
          >
            {title && (
              <TitleBar rounded={rounded}>
                <span className="title">{title}</span>
              </TitleBar>
            )}
            {loading && !loadingType && (
              <Page>
                <LoaderWrapper
                  className="noscroller"
                  height={loadingPopupHeight || uploadingPopupHeight}
                  bgColor={bodyBgColor}
                >
                  <Loader className="loader" />
                  {loadingText && <LoaderText>{loadingText}</LoaderText>}
                </LoaderWrapper>
              </Page>
            )}
            {loading && loadingType === loadType.DOTS && (
              <ContentText>
                <CenterText>
                  {loadingText}
                  <LoadingDots />
                </CenterText>
              </ContentText>
            )}
            {loading && loadingType === loadType.JUST_TEXT && (
              <ContentText>
                <CenterText>{loadingText}</CenterText>
              </ContentText>
            )}
            {!(loading && loadingType === loadType.DOTS) && (
              <Body bgColor={bodyBgColor} bodyPadding={bodyPadding}>
                {sizeMediumLarge && (
                  <Scrollbars
                    ref={scrollBarRef}
                    style={{ ...scrollableStyle, ...customBodyStyle }}
                    autoHeight={newAutoHeight}
                    autoHeightMax={autoHeightMax}
                  >
                    {!loading && contentText && (
                      <ContentText>{contentText}</ContentText>
                    )}
                    {!loading && children}
                  </Scrollbars>
                )}
                {size === popupSize.SMALL && (
                  <div>
                    {contentText && (
                      <ContentText style={{ ...customBodyStyle }}>
                        {contentText}
                      </ContentText>
                    )}
                    {children}
                  </div>
                )}
              </Body>
            )}
            {!((loading && loadingType === loadType.DOTS) || noFooter) && (
              <MainFooter
                rounded={rounded}
                centralizeButtons={centralizeButtons}
                size={size}
                showFooterBorder={showFooterBorder}
                bgColor={footerBgColor}
              >
                {!loading && (
                  <LeftWrapper>
                    {leftButtons &&
                      leftButtons.map((leftButton, index) => (
                        <PopupNewActionButton
                          key={`${leftButton.text}${title}${index}`}
                          {...leftButton}
                        />
                      ))}
                  </LeftWrapper>
                )}
                {!loading && (
                  <RightWrapper>
                    {rightButtons &&
                      rightButtons.map((rightButton, index) => (
                        <PopupNewActionButton
                          key={`${rightButton.text}${title}${index}`}
                          {...rightButton}
                        />
                      ))}
                  </RightWrapper>
                )}
              </MainFooter>
            )}
            {showCloseTopRight && (
              <CloseLink
                data-testid={`${dataTestId}-close`}
                onClick={closeLink ? null : handleClosePopup}
                to={closeLink || '#'}
              >
                <Cross color={colorsOptions.greys['grey-100']} height={48} />
              </CloseLink>
            )}
            {showArrowDownTopRight && (
              <CloseLink
                onClick={closeLink ? null : handleClosePopup}
                to={closeLink || '#'}
              >
                <Arrow
                  color={colorsOptions.greys['grey-100']}
                  hoverColor={colorsOptions.greys['grey-100']}
                  direction={directions.down}
                  height={48}
                />
              </CloseLink>
            )}
          </Popup>
        </PopupWrapper>
      </>
    </Portal>
  )
}

PopupNew.propTypes = {
  closePopup: PropTypes.func,
  title: PropTypes.string,
  /* if you intend to show just text in the popup */
  contentText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  leftButtons: PropTypes.array,
  rightButtons: PropTypes.array,
  /* the body content of the popup */
  children: PropTypes.node,
  /* to show or not show the close(X) button on the top right */
  showCloseTopRight: PropTypes.bool,
  /* to show or not show the close(v) button as an arrow down on the top right */
  showArrowDownTopRight: PropTypes.bool,
  /* the url to  */
  closeLink: PropTypes.string,
  size: PropTypes.string,
  /* choose the loading type */
  loadingType: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  /* bind an action to a keypress on the popup */
  popupKeypress: PropTypes.func,
  /* do not show the footer component */
  noFooter: PropTypes.bool,
  /* when a popup should be displayed over a popup */
  overPopup: PropTypes.bool,
  width: PropTypes.number,
  bodyMaxHeight: PropTypes.number,
  thicker: PropTypes.bool,
  showFooterBorder: PropTypes.bool,
  loadingPopupHeight: PropTypes.number,
  /* body height grows with content */
  autoHeight: PropTypes.bool,
  /* custom styling for the body scrollbar */
  customBodyStyle: PropTypes.object,
  bodyPadding: PropTypes.string,
  centralizeButtons: PropTypes.bool,
  footerBgColor: PropTypes.string,
  bodyBgColor: PropTypes.string,
  rounded: PropTypes.bool,
  /* test id */
  dataTestId: PropTypes.string,
  /* to allow scrolling events */
  scrollBarRef: PropTypes.object,
  /* Center the popup based on view port not the sidebar */
  trueCenter: PropTypes.bool
}

export default PopupNew
