/* eslint-disable max-len */
import { number, string } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/constants'

export const NumberSvg = styled.svg`
  height: ${prop('size', 17)}px;
  width: ${prop('size', 17)}px;
  .a {
    fill: none;
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .b {
    fill: ${prop('color', colorsOptions.greys['grey-100'])};
  }
`

function NumberIcon({ color, size }) {
  return (
    <NumberSvg
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      size={size}
      viewBox="0 0 17 17"
    >
      <g transform="translate(0.5 0.5)">
        <rect className="a" width="16" height="16" rx="2" />
        <path
          className="b"
          d="M-3.368-5.52H-4.376l-.9.608v1.08l.792-.52V0h1.12ZM1.656-.944h-1.9L.888-2.28A2.745,2.745,0,0,0,1.72-3.992a1.641,1.641,0,0,0-1.744-1.6,1.633,1.633,0,0,0-1.736,1.6h.992A.659.659,0,0,1-.08-4.664a.646.646,0,0,1,.688.672A1.691,1.691,0,0,1,.1-2.9L-1.752-.72V0H1.656ZM3.512-2.376H3.92c.568,0,.848.288.848.776a.691.691,0,0,1-.72.728.73.73,0,0,1-.816-.608H2.248A1.655,1.655,0,0,0,4.12.072,1.6,1.6,0,0,0,5.88-1.544a1.412,1.412,0,0,0-.832-1.32A1.35,1.35,0,0,0,5.8-4.072a1.626,1.626,0,0,0-1.744-1.52A1.625,1.625,0,0,0,2.32-4.072h.992A.657.657,0,0,1,4-4.664a.64.64,0,0,1,.688.64.678.678,0,0,1-.768.7H3.512Z"
          transform="translate(8 10.5)"
        />
      </g>
    </NumberSvg>
  )
}

NumberIcon.propTypes = {
  color: string,
  size: number,
}

export default NumberIcon
