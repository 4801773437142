import { sizing } from '../../fundamentals/text-styles/text-styles'
import { iconSizes } from '../icons/icons-sizes'
import {
  PrimaryButton,
  PrimaryDestructiveButton,
  LinkButton,
  SecondaryHollowButton,
  SecondaryWhiteFillButton,
  SecondaryInvertedButton,
  SecondaryDestructiveHollowButton,
  SecondaryDestructiveWhiteFillButton,
  SecondaryDestructiveInvertedButton,
  TertiaryHollowButton,
  TertiaryWhiteFillButton,
  GhostButton,
  GhostInvertedButton,
  GhostDestructiveButton,
  GhostDestructiveInvertedButton
} from './styled-components'

const buttonSizeOptions = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
}

const buttonLinkSizeOptions = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge'
}

const buttonTypeOptions = {
  STANDARD: 'standard',
  'ICON-ONLY': 'icon-only'
}

const buttonVariantOptions = {
  PRIMARY: 'primary',
  'PRIMARY-DESTRUCTIVE': 'primary-destructive',

  'SECONDARY-HOLLOW': 'secondary-hollow',
  'SECONDARY-WHITE-FILL': 'secondary-white-fill',
  'SECONDARY-INVERTED': 'secondary-inverted',
  'SECONDARY-DESTRUCTIVE-HOLLOW': 'secondary-destructive-hollow',
  'SECONDARY-DESTRUCTIVE-WHITE-FILL': 'secondary-destructive-white-fill',
  'SECONDARY-DESTRUCTIVE-INVERTED': 'secondary-destructive-inverted',

  'TERTIARY-HOLLOW': 'tertiary-hollow',
  'TERTIARY-WHITE-FILL': 'tertiary-white-fill',

  GHOST: 'ghost',
  'GHOST-INVERTED': 'ghost-inverted',
  'GHOST-DESTRUCTIVE': 'ghost-destructive',
  'GHOST-DESTRUCTIVE-INVERTED': 'ghost-destructive-inverted',

  LINK: 'link'
}

const buttonVariantComponentOptions = {
  [buttonVariantOptions.PRIMARY]: PrimaryButton,
  [buttonVariantOptions['PRIMARY-DESTRUCTIVE']]: PrimaryDestructiveButton,

  [buttonVariantOptions['SECONDARY-HOLLOW']]: SecondaryHollowButton,
  [buttonVariantOptions['SECONDARY-WHITE-FILL']]: SecondaryWhiteFillButton,
  [buttonVariantOptions['SECONDARY-INVERTED']]: SecondaryInvertedButton,
  [buttonVariantOptions['SECONDARY-DESTRUCTIVE-HOLLOW']]:
    SecondaryDestructiveHollowButton,
  [buttonVariantOptions['SECONDARY-DESTRUCTIVE-WHITE-FILL']]:
    SecondaryDestructiveWhiteFillButton,
  [buttonVariantOptions['SECONDARY-DESTRUCTIVE-INVERTED']]:
    SecondaryDestructiveInvertedButton,

  [buttonVariantOptions['TERTIARY-HOLLOW']]: TertiaryHollowButton,
  [buttonVariantOptions['TERTIARY-WHITE-FILL']]: TertiaryWhiteFillButton,

  [buttonVariantOptions.GHOST]: GhostButton,
  [buttonVariantOptions['GHOST-INVERTED']]: GhostInvertedButton,
  [buttonVariantOptions['GHOST-DESTRUCTIVE']]: GhostDestructiveButton,
  [buttonVariantOptions['GHOST-DESTRUCTIVE-INVERTED']]:
    GhostDestructiveInvertedButton,

  [buttonVariantOptions.LINK]: LinkButton
}

const buttonSizeStyleOptions = {
  [buttonSizeOptions.SMALL]: {
    iconSize: iconSizes['icon-s'],
    height: '32px',
    ...sizing['text-r'],
    borderWidth: '1px',
    padding: '0 1rem'
  },
  [buttonSizeOptions.MEDIUM]: {
    iconSize: iconSizes['icon-m'],
    height: '40px',
    ...sizing['text-l'],
    borderWidth: '1.25px',
    padding: '0 2rem'
  },
  [buttonSizeOptions.LARGE]: {
    iconSize: iconSizes['icon-l'],
    height: '48px',
    ...sizing['text-xl'],
    borderWidth: '1.5px',
    padding: '0 2.5rem'
  }
}

const buttonLinkStyleOptions = {
  [buttonLinkSizeOptions.SMALL]: {
    ...sizing['text-s']
  },
  [buttonLinkSizeOptions.MEDIUM]: {
    ...sizing['text-r']
  },
  [buttonLinkSizeOptions.LARGE]: {
    ...sizing['text-l']
  },
  [buttonLinkSizeOptions.XLARGE]: {
    ...sizing['text-xl']
  }
}

export {
  buttonSizeOptions,
  buttonTypeOptions,
  buttonVariantOptions,
  buttonVariantComponentOptions,
  buttonSizeStyleOptions,
  buttonLinkSizeOptions,
  buttonLinkStyleOptions
}
