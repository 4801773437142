import { popupSize } from '../components/popup-new/constants'

export const getHeight = (windowHeight, isMobile, size) => {
  const contentHeight = windowHeight - 50
  switch (size) {
    case popupSize.LARGE: {
      return {
        scrollableStyle: {
          height: !isMobile
            ? contentHeight - contentHeight * 0.1 - 130
            : contentHeight - 200,
        },
        uploadingPopupHeight: windowHeight - (14.5 * windowHeight) / 100,
      }
    }
    case popupSize.X_LARGE: {
      return {
        scrollableStyle: {
          height: !isMobile
            ? contentHeight - contentHeight * 0.1 - 200
            : contentHeight - 200,
        },
        uploadingPopupHeight: windowHeight - (19 * windowHeight) / 100,
      }
    }
    case popupSize.MEDIUM: {
      return {
        scrollableStyle: {
          height: !isMobile
            ? contentHeight - contentHeight * 0.1 - 200
            : contentHeight - 200,
        },
        uploadingPopupHeight: 350,
      }
    }
    default:
      return { scrollableStyle: {}, uploadingPopupHeight: 0 }
  }
}
