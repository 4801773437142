import styled, { keyframes } from 'styled-components'
import { prop, ifProp } from 'styled-tools'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import { PopupDefaultContainer } from '../../components/popup/styled-components'
import Input from '../../components/input'
import dropdownArrow from '@images/downarrow.svg'
import searchIcon from '@images/searchicon.svg'
import { SelectBoxStyle } from '../Filter/styledComponents'
import { SettingsFormVerticalGroup } from '../ProjectSettings/styledComponents'
import AssigneeComp from '../PinDetails/components/Tasks/components/Task/Assignee/Assignee'
import colorsOptions from '../../components/stories/fundamentals/colors-styles/constants'
import {
  sizing,
  weights,
} from '../../components/stories/fundamentals/text-styles/text-styles'

export const TopSection = styled.div`
  &:after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
`

export const TopSectionWrapper = styled.div`
  @media (max-width: 767px) {
    position: fixed;
    top: 50px;
    width: 100%;
    left: 0;
    z-index: 9999;
    background: ${colorsOptions.greys['grey-10']};
  }
`
export const NoFound = styled.div`
  padding: 4px 12px;
`

export const LanguageSelect = styled.div`
  display: block;
  vertical-align: middle;
  width: 100%;
`

export const LeftBarText = styled.div`
  float: left;
  width: 44%;
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 36%;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
    padding: 6px 16px 11px;
    text-align: center;
  }
`

export const RightMenu = styled.div`
  position: fixed;
  right: 50px;
  z-index: 150;
  width: 56%;
  float: right;
  margin-top: ${({ isMobile }) => (isMobile ? '0' : '32px')};
  text-align: right;
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 64%;
  }
  &.wide {
    @media (min-width: 768px) and (max-width: 1311px) {
      width: 100%;
    }
  }
`

export const OptionsBlock = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  &:after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  @media (max-width: 767px) {
    margin-top: 0;
    padding: 6px 8px 2px;
    border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
    margin-bottom: 0;
  }
`

export const LeftBlock = styled.div`
  float: left;
  display: inline-block;
`

export const RightBlock = styled.div`
  float: right;
  display: inline-block;
  @media (max-width: 767px) {
    display: block;
    float: none;
  }
`
export const Title = styled.span`
  ${weights['text-700']};
  ${sizing['text-r']};
  color: ${colorsOptions.greys['grey-100']};
  text-decoration: none;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 767px) {
    ${sizing['text-r']};
  }
  &:hover {
    color: ${colorsOptions.greys['grey-100']};
    text-decoration: none;
  }
`

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  thead {
    width: 100%;
    tr {
      width: 100%;
      border: 1px solid ${colorsOptions.greys['grey-20']};
      th {
        cursor: pointer;
        color: ${colorsOptions.greys['grey-50']};
        ${weights['text-600']};
        ${sizing['text-r']};
        text-align: left;
        padding: 9px 1%;
        background: ${colorsOptions.greys.white};
        &.active {
          color: ${colorsOptions.docuToolsOrange.orange};
        }
        &:hover {
          color: ${colorsOptions.docuToolsOrange.orange};
        }
        &.center {
          text-align: center;
        }
      }
    }
  }
  tbody {
    width: 100%;
    tr {
      height: 56px;
      width: 100%;
      background: ${colorsOptions.greys['grey-05']};
      border-right: 1px solid ${colorsOptions.greys['grey-20']};
      border-left: 1px solid ${colorsOptions.greys['grey-20']};
      &.customrowtwo {
        width: 100%;
        border: 0 !important;
        border-bottom: 1px solid ${colorsOptions.greys['grey-40']} !important;
        td {
          padding: 5px 2% !important;
        }
      }

      &:hover {
        background: ${colorsOptions.greys['grey-20']};
      }

      &:nth-child(even) {
        &:hover {
          background: ${colorsOptions.greys['grey-20']};
        }
        background: ${colorsOptions.greys.white};
        border: 1px solid ${colorsOptions.greys['grey-20']};
      }
      td {
        text-align: left;
        padding: 6px 1%;
        ${weights['text-500']};
        ${sizing['text-r']};
        color: ${colorsOptions.greys['grey-100']};
        position: relative;
        @media (max-width: 767px) {
          ${sizing['text-r']};
        }
        &.active {
          border-left: 5px solid ${colorsOptions.docuToolsOrange.orange};
        }
      }
    }
  }
`
export const Content = styled.div`
  margin: 15px 0;
`

export const CreatedBy = styled.p`
  color: ${colorsOptions.greys['grey-60']};
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${weights['text-500']};
  ${sizing['text-r']};
`

export const TableButton = styled(Button)`
  padding: 8px 0;
  width: 79%;
  max-width: 200px;
  margin: 0 auto;
`
export const TableButtonDownload = styled.a`
  width: 79%;
  max-width: 200px;
  text-decoration: none;
  padding: 10px 11px;
  display: inline-block;
  color: ${colorsOptions.greys['grey-100']};
  text-align: center;
  ${sizing['text-l']};
  border-radius: 40px;
  border: 1px solid ${colorsOptions.greys['grey-30']};

  background: ${colorsOptions.greys.white};
  ${weights['text-600']};

  &:hover {
    background: ${colorsOptions.greys['grey-20']};
    border-color: ${colorsOptions.greys['grey-30']};
    cursor: pointer;
  }
  &:disabled {
    background: none !important;
    opacity: 0.6;
    color: ${colorsOptions.greys['grey-50']};
    border: 1px solid ${colorsOptions.greys['grey-30']};
    cursor: not-allowed;
  }
  @media (max-width: 767px) {
    padding: 7px 32px;
    display: inline-block;
    ${sizing['text-r']};
  }
`

export const ProgessBlock = styled.div`
  display: block;
`
export const ProgessBarWrapper = styled.div`
  width: 100%;
  height: 6px;
  margin-bottom: 5px;
  @media (max-width: 767px) {
    width: 100%;
    height: 6px;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`
export const ProgressBar = styled.div`
  height: 100%;
  background: ${colorsOptions.docuToolsOrange.orange};
  @media (max-width: 767px) {
    margin-right: -15px;
    margin-left: -15px;
  }
`

export const ProgressText = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${weights['text-500']};
  ${sizing['text-r']};
  color: ${({ error }) =>
    error
      ? colorsOptions.secondaryColors.red.strong
      : colorsOptions.docuToolsOrange.orange};
`

export const Info = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  border-bottom: 1px solid ${colorsOptions.greys['grey-50']};
  margin-bottom: 10px;
`

export const SearchFieldTwo = styled(Input)`
  border: none;
  color: ${colorsOptions.greys['grey-100']};
  ${weights['text-600']};
  ${sizing['text-r']};
  background-size: 15px 15px;
  padding: 8px 24px 8px 12px;
  vertical-align: middle;
  display: block;
  width: 100%;
  ${({ showSearchIcon }) =>
    showSearchIcon && `background: url("${searchIcon}") no-repeat 100% 8px;`}
  &.search_field {
    padding: 8px 24px 8px 0;
    background: url('${dropdownArrow}') center right no-repeat;
    background-size: 14px 14px;
  }
`

export const TitleInput = styled(Input)`
  border-bottom: 1px solid ${colorsOptions.greys['grey-50']};
`

export const SearchResultHeading = styled.div`
  ${sizing['text-s']};
  ${weights['text-500']};
  color: ${colorsOptions.docuToolsOrange.orange};
  padding: 8px 12px 13px;
`

export const CreatingText = styled.div`
  ${sizing['text-l']};
  ${weights['text-600']};
  color: ${colorsOptions.docuToolsOrange.orange};
  text-align: center;
  position: absolute;
  top: 60%;
  left: 51%;
  transform: translate(-60%, -51%);
`

export const SearchResultBlock = styled.div`
  display: block;
`

export const SearchResult = styled.div`
  ${sizing['text-r']};
  ${weights['text-500']};
  color: ${colorsOptions.greys['grey-100']};
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
`

export const TitleBar = styled.div`
  background: ${colorsOptions.greys['grey-10']};
  padding: 16px 10px 16px 16px;
  margin-bottom: 2px;
  border-bottom: 1px solid ${colorsOptions.greys['grey-40']};
  span {
    ${weights['text-600']};
    color: ${colorsOptions.greys['grey-100']};
    ${sizing['text-l']};
  }
`

export const CloseBtn = styled.button`
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border-radius: 26px;
  height: 25px;
  width: 25px;
  overflow: hidden;
  float: right;
  text-align: center;
  margin-top: 3px;
`

export const EditDetailPopup = styled(PopupDefaultContainer)`
  max-width: ${({ bigger }) => (bigger ? '80%' : '844px')};
  width: 100%;
  transition: all 0.4s;
  @media (max-width: 1160px) {
    width: 80%;
  }
`

export const InsideBlock = styled.div`
  padding: 15px 16px;
`

export const PopupBody = styled.div`
  padding: 15px 16px 15px;
`
export const PopupBodyTwo = styled.div`
  padding: 0;
  overflow: hidden;
`

export const PopupFooter = styled.div`
  padding: 15px 16px 15px;
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 15px;
  }
`

export const FormContainer = styled.div`
  margin-bottom: 13px;
`

export const PdfPages = styled.div`
  border-bottom: 1px solid black;
  text-align: center;
  padding-bottom: 10px;
  &:last-child {
    border-bottom: none;
  }
`

export const SubHeading = styled.div`
  ${sizing['text-l']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  margin-bottom: 15px;
`
export const CheckBoxLabel = styled.label`
  ${sizing['text-l']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  margin-bottom: 5px;
  vertical-align: middle;
  margin-left: 10px;
  display: inline-block;
`

export const PopupHeading = styled.div`
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  ${sizing['text-l']};
  display: inline-block;
`

export const TemplateFormContainer = styled(SettingsFormVerticalGroup)`
  height: 100%;
`

export const TemplateTitleWrapper = styled.div`
  margin-bottom: 8px;
  position: relative;
  & > img {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 1%;
    top: 42%;
    cursor: pointer;
  }
`

export const DropZoneTitle = styled.span`
  ${weights['text-600']};
  ${sizing['text-r']};
  margin-top: 2em !important; // SettingsFormVerticalGroup would override this
  padding: 0 0.5em;
`

export const DropImageBox = styled.div`
  border: solid 1px ${colorsOptions.greys['grey-30']};
  border-radius: 8px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  background: ${ifProp('filled', 'transparent', colorsOptions.greys.white)};
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100px;
  @media (max-width: 767px) {
    width: 60%;
    margin-top: 0;
  }
`

export const Uploadtext = styled.div`
  color: ${colorsOptions.greys['grey-60']};
  ${weights['text-500']};
  ${sizing['text-s']};
`

const BaseDropZoneContent = styled.div`
  width: 100%;
  cursor: default;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5em;
`

export const SuccessWrapper = styled(BaseDropZoneContent)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${weights['text-600']};
  ${sizing['text-r']};
`

export const UploadItemNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const UploadItemName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
`

export const InfoRed = styled(UploadItemName)`
  color: ${colorsOptions.secondaryColors.red.strong};
`

export const UploadListItem = styled.div`
  display: flex;
  gap: 0.5em;
  width: 100%;
  padding-right: 0.5em;
  flex-wrap: nowrap;
  align-items: flex-start;
  ${sizing['text-s']};
  & > svg {
    flex: 0 0 auto;
  }
  & > button:last-child {
    margin-left: auto;
  }
`

export const UploadList = styled(BaseDropZoneContent)``

export const UploadZoneWrapper = styled.div`
  min-height: 120px;
  display: flex;
  align-items: center;
`

export const TemplateInfoSummary = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5em;
  padding: 0 0.5em;
  ${sizing['text-s']};
  color: ${colorsOptions.greys['grey-60']};
  ${weights['text-400']};
  & > span {
    flex: 1 1 auto;
    &:last-child {
      text-align: end;
    }
  }
`

export const BoldSpan = styled.span`
  font-weight: bold;
`

export const InsideSpantext = styled.span`
  color: ${colorsOptions.greys['grey-60']};
  ${weights['text-500']};
  ${sizing['text-s']};
  @media (max-width: 767px) {
    display: none;
  }
`

export const Selectlink = styled.span`
  color: ${colorsOptions.docuToolsOrange.orange};
  ${weights['text-600']};
  @media (max-width: 767px) {
    padding: 6px 31px;
    display: inline-block;
    text-align: center;
    ${sizing['text-s']};
    border-radius: 40px;
    border: 1px solid ${colorsOptions.greys['grey-30']};

    background: ${colorsOptions.greys.white};
    margin-bottom: 7px;

    &:hover {
      background: ${colorsOptions.greys['grey-20']};
      border-color: ${colorsOptions.greys['grey-30']};
    }
  }
`

export const SelectlinkA = styled.a`
  color: ${colorsOptions.greys['grey-100']};
  ${weights['text-600']};
`

export const AboutLink = styled.a`
  color: ${colorsOptions.greys['grey-60']};
  ${sizing['text-s']};
  ${weights['text-600']};
`
export const DropZoneStyle = styled.div`
  border: ${ifProp(
    'filled',
    'none',
    `2px dashed ${colorsOptions.greys['grey-50']}`,
  )};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: relative;
  & > div {
    border-width: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const InsideContent = styled.div`
  margin-top: 50px;
  text-align: center;
  margin-bottom: 50px;
`
export const CreatingProgressText = styled.div`
  margin-top: 10px;
  font-size: 16px;
  ${sizing['text-l']};
  ${weights['text-500']};
  color: ${colorsOptions.docuToolsOrange.orange};
`

export const NoteText = styled.div`
  ${sizing['text-l']};
  ${weights['text-400']};
  color: ${colorsOptions.greys['grey-60']};
  margin-top: 20px;
`
export const NoteTextWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  ${sizing['text-l']};
  ${weights['text-400']};
  color: ${colorsOptions.greys['grey-60']};
`

export const MessageBlock = styled.div`
  padding: 20px 0;
  svg {
    vertical-align: top;
  }
`

export const MessageInside = styled.div`
  margin-bottom: 10px;
  &.last {
    margin-bottom: 0;
  }
`

export const ErrorText = styled.div`
  display: inline-block;
  text-align: left;
  p {
    ${sizing['text-s']};
    ${weights['text-700']};
    color: ${colorsOptions.secondaryColors.red.strong};
    display: inline-block;
    margin-bottom: 3px;
    letter-spacing: 0.06px;
  }
  margin-left: 10px;
`

export const ErrorDetails = styled.ol`
  list-style: none;
  li {
    ${sizing['text-xs']};
    ${weights['text-500']};
    color: #707070;
    margin-bottom: 4px;
  }
`

export const SubmenusLink = styled.ul`
  padding: 0;
  display: inline-block;
  position: relative;
  margin-right: 16px;
  vertical-align: middle;
  &.last {
    @media (min-width: 768px) {
      margin-right: 0;
    }
  }
  ul {
    display: inline-block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 49px;
    right: 0;
    left: auto;
    width: 288px;
    border: 1px solid ${colorsOptions.greys['grey-20']};
    box-shadow: 0 1px 5px 0 rgba(20, 26, 33, 0.2);
    z-index: 1003;
    @media (max-width: 767px) {
      top: 40px;
      width: 289px;
    }

    &:after {
      position: absolute;
      right: 8px;
      top: -11px;
      width: 0;
      height: 0;
      content: '';
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid ${colorsOptions.greys['grey-20']};
    }

    &:before {
      position: absolute;
      right: 8px;
      top: -9px;
      width: 0;
      height: 0;
      content: '';
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #fff transparent;
      z-index: 3;
    }

    &:hover {
      &:before {
        border: none;
      }
    }
  }

  li {
    list-style-type: none;
    text-align: left;
    border-bottom: 1px solid ${colorsOptions.greys['grey-20']};
    &:last-child {
      border-bottom: none;
    }
  }
`

export const MenuLinks = styled.a`
  text-align: left;
  text-decoration: none;
  padding: 9px 12px;
  color: ${({ disabled }) =>
    disabled
      ? colorsOptions.greys['grey-50']
      : colorsOptions.greys['grey-100']};
  ${sizing['text-l']};
  display: block;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background: ${colorsOptions.greys.white};
  width: 100%;
  ${weights['text-600']};
  ${({ disabled }) =>
    !disabled &&
    `
    &:hover{
      background: ${colorsOptions.greys['grey-20']};
      text-decoration:none;
    }
  `};
  @media (max-width: 767px) {
    padding: 9px 12px;
  }
`

export const Links = styled(Link)`
  padding: 9px 12px;
  color: ${({ disabled }) =>
    disabled
      ? colorsOptions.greys['grey-50']
      : colorsOptions.greys['grey-100']};
  ${sizing['text-l']};
  display: block;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background: ${colorsOptions.greys.white};
  width: 100%;
  text-decoration: none;
  ${weights['text-600']};
  ${({ disabled }) =>
    !disabled &&
    `
    &:hover{
      background: ${colorsOptions.greys['grey-20']};
      text-decoration:none;
    }
  `};
  @media (max-width: 767px) {
    padding: 9px 12px;
  }
`

export const Anchor = styled.a`
  color: ${colorsOptions.greys['grey-100']};
  border-radius: 40px;
  background: ${colorsOptions.greys.white};
  display: block;
  float: left;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
  letter-spacing: 1px;
  box-shadow: 0 1px 5px 0 rgba(20, 26, 33, 0.2);
  @media (max-width: 767px) {
    box-shadow: none;
    background: none;
  }
`
export const FormControl = styled.div`
  position: relative;
  margin-bottom: 10px;
`

export const Label = styled.label`
  margin-bottom: 5px;
  width: 100%;
  display: block;
`

export const Labelmsg = styled.span`
  ${sizing['text-r']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-100']};
  margin-bottom: 10px;
  display: inline-block;
`

export const PopupRow = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  padding: 8px 0;
  &:after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
`
export const ContentRow = styled.div`
  border-bottom: 1px solid ${colorsOptions.greys['grey-30']};
  padding: 10px 15px;
  position: relative;
  @media (max-width: 767px) {
    background: ${colorsOptions.greys.white};
  }
`

export const Icon = styled.div`
  width: 35px;
  height: 35px;
  float: left;
`
export const Close = styled.button`
  position: absolute;
  top: 50%;
  right: 15px;
  border-radius: 17px;
  height: 20px;
  width: 20px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  transform: translateY(-50%);
`
export const Downloadbtn = styled.button`
  position: absolute;
  top: 50%;
  right: 4px;
  border-radius: 17px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  transform: translateY(-50%);
`

export const DownloadbtnHref = styled.a`
  position: absolute;
  text-decoration: none;
  top: 50%;
  right: 4px;
  border-radius: 17px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  transform: translateY(-50%);
`

export const Detail = styled.div`
  float: left;
  width: 70%;
`
export const ProjectName = styled.div`
  ${sizing['text-l']};
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-60']};
  word-wrap: break-word;
  margin-top: 8px;
  &.active {
    color: ${colorsOptions.docuToolsOrange.orange};
  }
`
export const MiddleSectionWrapper = styled.div`
  margin-top: 86px;
  max-height: calc(100vh - 189px);
  overflow-y: auto;
  margin-bottom: 50px;
`

export const TemplateSearchBlock = styled.div`
  position: absolute;
  top: 38px;
  left: -250px;
  background: ${colorsOptions.greys.white};
  width: 300px;
  z-index: 999999999999999;
  box-shadow: 0 1px 5px 0 rgba(20, 26, 33, 0.2);
  border: 1px solid ${colorsOptions.greys['grey-20']};

  &:after {
    position: absolute;
    left: 253px;
    top: -11px;
    width: 0;
    height: 0;
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${colorsOptions.greys['grey-20']};
  }

  &:before {
    position: absolute;
    left: 253px;
    top: -9px;
    width: 0;
    height: 0;
    content: '';
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    z-index: 3;
  }
`

export const ExcelPreview = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  img {
    margin-bottom: 10px;
  }
  ${weights['text-600']};
  color: ${colorsOptions.greys['grey-60']};
`

export const NoReports = styled.div`
  background: ${colorsOptions.greys.white};
  text-align: center;
  padding: 50px 20px;
  border-radius: 4px;
  border: 1px solid ${colorsOptions.greys['grey-30']};
  margin-top: 10px;

  img {
    margin-bottom: -60px;
    margin-top: -90px;
    max-width: 300px;
  }

  h3 {
    color: ${colorsOptions.greys['grey-100']};
    ${weights['text-700']};
    ${sizing['text-xl']};
    margin-bottom: 0;
    margin-top: 15px;
  }

  p {
    color: ${colorsOptions.greys['grey-60']};
    margin-top: 0;
    ${sizing['text-l']};
    ${weights['text-500']};
    margin-bottom: 20px;
  }
`

export const UploadButtonLink = styled(Button)`
  padding: 6px 28px;
  display: inline-block;
  margin: 0 5px 10px;
  ${sizing['text-l']};
  text-decoration: none;
`
export const HeadingSection = styled.div`
  margin-top: -20px;
`
export const Page = styled.div`
  padding: 10px 48px 0 48px;
  position: relative;
  @media (max-width: 767px) {
    padding: 0;
  }
`
export const MobilePage = styled.div`
  padding: 10px 48px 50px 48px;
  position: relative;
  @media (max-width: 767px) {
    padding: 16px;
  }
`

export const PageNumbers = styled.p`
  text-align: center;
`

export const SelectMediaWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 13px;
  margin-left: -12px;
  align-items: flex-end;
  > div {
    width: 100%;
    margin-left: 12px;
  }
  > span {
    margin-left: 13px;
    white-space: nowrap;
  }

  div.label {
    display: inline-block;
    margin-right: 10px;
    ${ifProp('disabled', `color: ${colorsOptions.greys['grey-50']};`)}
  }
  div.select {
    display: inline-block;
    height: 22px;
    max-width: calc(100% - ${prop('labelWidth', 0)}px);
    width: 300px;
    .Select-value-label {
      ${ifProp('disabled', `color: ${colorsOptions.greys['grey-50']};`)}
    }
  }
  div.selectSinceDate {
    display: inline-block;
    margin-left: 16px;
  }
`

export const SelectStyle = {
  ...SelectBoxStyle({}),
  option: (base) => ({
    ...base,
    fontFamily: `${weights['text-500'].fontFamily} !important`,
    ...sizing['text-l'],
    color: '#666666!important',
  }),
}

export const CenteredText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  min-height: 10vh;
`
export const ShareWrapper = styled.div`
  display: flex;
  padding: 16px 25px 0 25px;
  flex-direction: column;
`

export const ShareButton = styled(Button)`
  width: fit-content;
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 8px 42px;
`

export const CopyButton = styled(Button)`
  width: fit-content;
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 8px 29px;
`
export const ShareSubTitle = styled.div`
  display: flex;
  align-items: end;
  img {
    margin-right: 8px;
  }
  ${sizing['text-r']};
`
export const ShareContent = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-left: 30px;
  margin-top: 21px;
`
export const ShareLink = styled(ShareContent)`
  margin-top: 16px;
  color: ${colorsOptions.greys['grey-60']};
  font-style: italic;
`

export const ShareErrorWrapper = styled.div`
  padding: 10px 14px;
  > span {
    margin: 10px;
  }
`
export const headerBackground = {
  inlineStyle: {
    backgroundColor: colorsOptions.greys['grey-20'],
    borderBottom: `0.5px solid ${colorsOptions.greys['grey-30']}`,
    borderTop: `0.5px solid ${colorsOptions.greys['grey-30']}`,
  },
}

export const headerBorderRadius = {
  fistChild: {
    inlineStyle: {
      ...headerBackground.inlineStyle,
      borderTopLeftRadius: '8px',
      borderLeft: `0.5px solid ${colorsOptions.greys['grey-30']}`,
    },
  },
  LastChild: {
    inlineStyle: {
      ...headerBackground.inlineStyle,
      borderTopRightRadius: '8px',
      borderRight: `0.5px solid ${colorsOptions.greys['grey-30']}`,
    },
  },
}

export const headerAignmentCenter = {
  inlineStyle: {
    ...headerBackground.inlineStyle,
    justifyContent: 'center',
  },
}

const spinAnimationKeyframe = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Loader = styled.div`
  &.loader {
    position: relative;
    top: -20%;
    left: 25%;
    margin: 0px 0px 12px 0px;
    text-align: center;
    border: 5px solid ${colorsOptions.docuToolsOrange.orange};
    border-radius: 50%;
    border-top: 5px solid ${colorsOptions.greys.white};
    width: 40px;
    height: 40px;
    animation: ${spinAnimationKeyframe} 2s linear infinite;
  }
`

export const LoaderWrapper = styled.div`
  border-width: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  display: block;
  align-items: center;
  justify-content: center;
  & > span {
    ${sizing['text-r']};
    color: ${colorsOptions.docuToolsOrange.orange};
  }
`
export const Assignee = styled(AssigneeComp)`
  > div {
    &:first-child {
      width: 45px;
      padding: 0 8px 0 0;
    }
    & > span,
    > div {
      ${sizing['text-r']};
      ${weights['text-500']};
    }
  }
`
export const FooterButton = styled(Button)`
  padding: 8px 42px;
`
