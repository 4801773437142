import React, { useState, createContext, useCallback } from 'react'
import { v4 as uuid } from 'uuid'

const SnackbarContext = createContext()

const SnackbarContextProvider = ({ children }) => {
  const [snackbarsList, setSnackbarsList] = useState([])

  const addSnackbar = useCallback(
    ({
      message,
      snackbarType,
      buttonAction,
      isOpen,
      isOpenAways,
      hideCloseButton,
      timeToHide
    }) => {
      const newSnackbar = {
        id: uuid(),
        message,
        snackbarType,
        buttonAction,
        isOpen,
        isOpenAways,
        hideCloseButton,
        timeToHide
      }
      setSnackbarsList((prev) => [...prev, newSnackbar])
    },
    []
  )

  const removeSnackbar = useCallback((id) => {
    setSnackbarsList((prev) => prev.filter((snack) => snack.id !== id))
  }, [])

  return (
    <SnackbarContext.Provider
      value={{
        snackbarsList,
        addSnackbar,
        removeSnackbar
      }}
    >
      {children}
    </SnackbarContext.Provider>
  )
}

export { SnackbarContextProvider, SnackbarContext }
