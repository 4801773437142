import { useEffect, useState } from 'react'
import { number } from 'prop-types'

const LoadingDots = ({ max = 3, time = 1000 }) => {
  let dotTimeout
  const [dots, setDots] = useState('.'.padEnd(max))

  useEffect(() => {
    return () => {
      clearTimeout(dotTimeout)
    }
  }, [])

  useEffect(() => {
    clearTimeout(dotTimeout)
    dotTimeout = setTimeout(() => {
      let newDots = '.'
      const currentDots = dots.replace(/ /g, '')
      if (currentDots.length < max) {
        newDots = `${currentDots}.`
      }

      newDots = newDots.padEnd(max)

      setDots(newDots)
    }, time)
  }, [])

  return <span>{dots}</span>
}

LoadingDots.propTypes = {
  max: number,
  time: number
}

export default LoadingDots
