import { PropTypes } from 'prop-types'
import { FloatingLabelGrey, TitleTextarea, FormContainer } from '../styled-components'

const TextareaWithFloatingLabel = ({
  className,
  value = '',
  defaultValue,
  label,
  onChange,
  editable = true
}) => (
  <FormContainer className={className}>
    <FloatingLabelGrey visible={value && value !== ''}>
      {label}
    </FloatingLabelGrey>
    <TitleTextarea
      type="text"
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={label}
      disabled={!editable}
      editable={editable}
    />
  </FormContainer>
)

TextareaWithFloatingLabel.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  editable: PropTypes.bool
}

export default TextareaWithFloatingLabel
