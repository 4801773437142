/* eslint-disable max-len */
import { number, oneOfType, string } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/constants'

const TagSvg = styled.svg`
  width: ${prop('size', 17)}px;
  height: ${prop('size', 17)}px;
  .a,
  .d {
    fill: none;
  }
  .a {
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .b {
    fill: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .c {
    stroke: none;
  }
`

function TagIcon({ color, size, padding }) {
  return (
    <TagSvg
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      padding={padding}
      viewBox="0 0 17 17"
    >
      <g transform="translate(0.054 0.054)">
        <path
          className="a"
          d="M2,0H14a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
          transform="translate(0.446 0.446)"
        />
        <g transform="translate(7.422 16.511) rotate(-135)">
          <g transform="translate(0.5 2.25)">
            <path
              className="b"
              d="M10.382,10.113l2.59,3.063-2.59,3.063H3.114V10.113h7.268M10.772,9H2.557A.557.557,0,0,0,2,9.556V16.8a.557.557,0,0,0,.557.557h8.215a.251.251,0,0,0,.223-.111l3.147-3.731a.585.585,0,0,0,0-.668L10.994,9.11A.251.251,0,0,0,10.772,9Z"
              transform="translate(-2 -8.999)"
            />
          </g>
        </g>
        <g className="a" transform="translate(5.431 5.431)">
          <circle className="c" cx="1" cy="1" r="1" />
          <circle className="d" cx="1" cy="1" r="1.5" />
        </g>
      </g>
    </TagSvg>
  )
}

TagIcon.propTypes = {
  color: string,
  size: number,
  padding: oneOfType([string, number]),
}

export default TagIcon
