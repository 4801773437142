/* eslint-disable max-len */
import { number, string } from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/constants'

export const AddSvg = styled.svg`
  height: ${prop('size', 17)}px;
  width: ${prop('size', 17)}px;
  .a,
  .b {
    fill: none;
  }
  .a {
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
    stroke-linecap: round;
    stroke-width: 3px;
  }
`

function AddIcon({ color, size }) {
  return (
    <AddSvg
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      size={size}
      viewBox="0 0 48 48"
    >
      <g transform="translate(18.414 24.071) rotate(-45)">
        <line className="a" y1="10" x2="10" transform="translate(-1 -1)" />
        <line className="a" x1="10" y1="10" transform="translate(-1 -1)" />
      </g>
      <rect className="b" width="16" height="16" />
    </AddSvg>
  )
}

AddIcon.propTypes = {
  color: string,
  size: number,
}

export default AddIcon
