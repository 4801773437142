import styled from 'styled-components'
import { number, string } from 'prop-types'
import { prop } from 'styled-tools'
import colorsOptions from '../../../components/stories/fundamentals/colors-styles/constants'

const CurrencySvg = styled.svg`
  width: ${prop('size', 17)}px;
  height: ${prop('size', 17)}px;
  .a {
    fill: none;
    stroke: ${prop('color', colorsOptions.greys['grey-100'])};
  }
  .b {
    fill: ${prop('color', colorsOptions.greys['grey-100'])};
  }
`

function CurrencyIcon({ color, size }) {
  return (
    <CurrencySvg
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      color={color}
      viewBox="0 0 17 17"
    >
      <g transform="translate(0.5 0.5)">
        <rect className="a" width="16" height="16" rx="2" />
        <g transform="translate(-16.333 -362.833)">
          <g transform="translate(21.333 365.833)">
            <path
              className="b"
              d="M14.408,6.523V3.932a3.5,3.5,0,0,1,1.182.477.5.5,0,0,0,.7-.159.409.409,0,0,0-.091-.659,3.886,3.886,0,0,0-1.8-.636v-.5a.455.455,0,1,0-.909,0v.455a2.375,2.375,0,0,0-2.455,2.2h0c0,1.364.977,1.909,2.455,2.273v2.7a3.8,3.8,0,0,1-1.659-.7.455.455,0,0,0-.682.091c-.227.3-.2.523.023.682a4.5,4.5,0,0,0,2.318.886v.5a.455.455,0,1,0,.909,0v-.477a2.4,2.4,0,0,0,2.5-2.273h0C16.908,7.614,16.158,6.932,14.408,6.523ZM12.135,5.068h0c0-.636.545-1.091,1.364-1.159V6.318C12.362,6,12.135,5.614,12.135,5.068Zm3.682,3.818c0,.659-.568,1.136-1.409,1.227v-2.5c1.159.318,1.409.7,1.409,1.25Z"
              transform="translate(-10.998 -2)"
            />
          </g>
        </g>
      </g>
    </CurrencySvg>
  )
}

CurrencyIcon.propTypes = {
  color: string,
  size: number,
}

export default CurrencyIcon
