/*
 *
 * PlanSelection actions
 *
 */

import * as actionTypes from './constants'

export function loadPlans(projectId, stateFilter, retries = 1) {
  return {
    type: actionTypes.LOAD_PLANS,
    projectId,
    stateFilter,
    retries,
  }
}

export function plansLoaded(plans) {
  return {
    type: actionTypes.PLANS_LOADED,
    plans,
  }
}

export function activePlansLoaded(plans) {
  return {
    type: actionTypes.ACTIVE_PLANS_LOADED,
    plans,
  }
}

export function toggleSubscription(
  subscriptionDetails,
  isSubscribed,
  retries = 1,
) {
  return {
    type: actionTypes.TOGGLE_SUBSCRIPTION,
    subscriptionDetails,
    isSubscribed,
    retries,
  }
}

export function plansLoadingError(error) {
  return {
    type: actionTypes.PLANS_LOADING_ERROR,
    error,
  }
}

export function filterPlans(filterWord) {
  return {
    type: actionTypes.FILTER_PLANS,
    filterWord,
  }
}

export function createFolder(
  name,
  parentId,
  projectId,
  planIndex,
  retries = 1,
) {
  return {
    type: actionTypes.CREATE_FOLDER,
    name,
    parentId,
    projectId,
    planIndex,
    retries,
  }
}

export function folderCreated(folder) {
  return {
    type: actionTypes.FOLDER_CREATED,
    folder,
  }
}

export function createFolderError(error) {
  return {
    type: actionTypes.CREATE_FOLDER_ERROR,
    error,
  }
}

export function clearCreateFolder() {
  return {
    type: actionTypes.CLEAR_CREATE_FOLDER,
  }
}

export function movePlan(planId, parentId, oldParentId, retries = 1) {
  return {
    type: actionTypes.MOVE_PLAN,
    planId,
    parentId,
    oldParentId,
    retries,
  }
}

export function planMoved(planId, oldParentId, parentId) {
  return {
    type: actionTypes.PLAN_MOVED,
    planId,
    oldParentId,
    parentId,
  }
}

export function movePlanError(error) {
  return {
    type: actionTypes.MOVE_PLAN_ERROR,
    error,
  }
}

export function clearMovePlan() {
  return {
    type: actionTypes.CLEAR_MOVE_PLAN,
  }
}

export function moveFolder(folderId, parentId, oldParentId, retries = 1) {
  return {
    type: actionTypes.MOVE_FOLDER,
    folderId,
    parentId,
    oldParentId,
    retries,
  }
}

export function folderMoved(folderId, oldParentId, parentId) {
  return {
    type: actionTypes.FOLDER_MOVED,
    folderId,
    oldParentId,
    parentId,
  }
}

export function moveFolderError(error) {
  return {
    type: actionTypes.MOVE_FOLDER_ERROR,
    error,
  }
}

export function clearMoveFolder() {
  return {
    type: actionTypes.CLEAR_MOVE_FOLDER,
  }
}

export function setProjectName(projectName) {
  return {
    type: actionTypes.SET_PROJECT_NAME,
    projectName,
  }
}

export function clearProject() {
  return {
    type: actionTypes.CLEAR_PROJECT,
  }
}

export function sortEntriesBy(sortBy, direction) {
  return {
    type: actionTypes.SET_SORT_BY,
    sortBy,
    direction,
  }
}

export function selectFolderBatch(folder) {
  return {
    type: actionTypes.SELECT_FOLDER_BATCH,
    folder,
  }
}
export function deselectFolderBatch(folder) {
  return {
    type: actionTypes.DESELECT_FOLDER_BATCH,
    folder,
  }
}
export function deselectAllFolderBatch() {
  return {
    type: actionTypes.DESELECT_ALL_FOLDER_BATCH,
  }
}

export function selectPlanBatch(plan) {
  return {
    type: actionTypes.SELECT_PLAN_BATCH,
    plan,
  }
}

export function deselectPlanBatch(plan) {
  return {
    type: actionTypes.DESELECT_PLAN_BATCH,
    plan,
  }
}
export function deselectAllPlanBatch() {
  return {
    type: actionTypes.DESELECT_ALL_PLAN_BATCH,
  }
}

export function updateFolder(folderId, options, parentId) {
  return {
    type: actionTypes.UPDATE_FOLDER,
    folderId,
    options,
    parentId,
  }
}
export function updateFolderDone(folderId, parentId, options) {
  return {
    type: actionTypes.UPDATE_FOLDER_DONE,
    folderId,
    parentId,
    options,
  }
}
export function updateFolderError(error) {
  return {
    type: actionTypes.UPDATE_FOLDER_ERROR,
    error,
  }
}

export function checkCanCreatePlan(retries = 1) {
  return {
    type: actionTypes.CHECK_CAN_CREATE_PLAN,
    retries,
  }
}
export function checkCanCreatePlanDone(canCreatePlan) {
  return {
    type: actionTypes.CHECK_CAN_CREATE_PLAN_DONE,
    canCreatePlan,
  }
}
export function checkCanCreatePlanError(error) {
  return {
    type: actionTypes.CHECK_CAN_CREATE_PLAN_ERROR,
    error,
  }
}

export function subscribeNotifications(ids, subscriptionType, retries = 1) {
  return {
    type: actionTypes.SUBSCRIBE_NOTIFICATION,
    ids,
    subscriptionType,
    retries,
  }
}
export function unsubscribeNotifications(ids, retries = 1) {
  return {
    type: actionTypes.UNSUBSCRIBE_NOTIFICATION,
    ids,
    retries,
  }
}
export function subscribeNotificationsDone(add, ids) {
  return {
    type: actionTypes.SUBSCRIBE_NOTIFICATION_DONE,
    add,
    ids,
  }
}
export function subscribeNotificationsError(error) {
  return {
    type: actionTypes.SUBSCRIBE_NOTIFICATION_ERROR,
    error,
  }
}
export function deleteEmptyFolder(folderId, parentId, retries = 1) {
  return {
    type: actionTypes.DELETE_EMPTY_FOLDER,
    folderId,
    parentId,
    retries,
  }
}
export function deleteEmptyFolderDone(folderId, parentId) {
  return {
    type: actionTypes.DELETE_EMPTY_FOLDER_DONE,
    folderId,
    parentId,
  }
}
export function deleteEmptyFolderError(error) {
  return {
    type: actionTypes.DELETE_EMPTY_FOLDER_ERROR,
    error,
  }
}

export function setPlanSort(sortBy) {
  return {
    type: actionTypes.SET_PLAN_SORT,
    sortBy,
  }
}

export function clearPlans() {
  return {
    type: actionTypes.CLEAR_PLANS,
  }
}
export function clearFolderPlans(folderId) {
  return {
    type: actionTypes.CLEAR_FOLDER_PLANS,
    folderId,
  }
}
export function getRootPlans(
  projectId,
  page,
  sort,
  stateFilter,
  search,
  refresh,
  sortDirection,
  retries = 1,
) {
  return {
    type: actionTypes.GET_ROOT_PLANS,
    projectId,
    page,
    sort,
    stateFilter,
    search,
    refresh,
    sortDirection,
    retries,
  }
}
export function getRootPlansDone(plans, timestamp, cached) {
  return {
    type: actionTypes.GET_ROOT_PLANS_DONE,
    plans,
    cached,
    timestamp,
  }
}
export function getRootPlansError(error) {
  return {
    type: actionTypes.GET_ROOT_PLANS_ERROR,
    error,
  }
}
export function getRootPlansWithFolders(
  projectId,
  folderIds,
  page,
  sort,
  stateFilter,
  search,
  refresh,
  retries = 1,
) {
  return {
    type: actionTypes.GET_ROOT_PLANS_WITH_FOLDERS,
    projectId,
    folderIds,
    page,
    sort,
    stateFilter,
    search,
    refresh,
    retries,
  }
}
export function getRootPlansWithFoldersDone(rootPlans, folders) {
  return {
    type: actionTypes.GET_ROOT_PLANS_WITH_FOLDERS_DONE,
    rootPlans,
    folders,
  }
}
export function getRootPlansWithFoldersError(error) {
  return {
    type: actionTypes.GET_ROOT_PLANS_WITH_FOLDERS_ERROR,
    error,
  }
}
export function getFolderPlans(folderId, page, refresh, retries = 1) {
  return {
    type: actionTypes.GET_FOLDER_PLANS,
    folderId,
    page,
    refresh,
    retries,
  }
}
export function getFolderPlansDone(folderId, plans, timestamp, cached) {
  return {
    type: actionTypes.GET_FOLDER_PLANS_DONE,
    folderId,
    plans,
    cached,
    timestamp,
  }
}
export function getFolderPlansError(folderId, error) {
  return {
    type: actionTypes.GET_FOLDER_PLANS_ERROR,
    error,
    folderId,
  }
}
export function openPlansFolder(folderId) {
  return {
    type: actionTypes.OPEN_PLANS_FOLDER,
    folderId,
  }
}
export function closePlansFolder(folderId) {
  return {
    type: actionTypes.CLOSE_PLANS_FOLDER,
    folderId,
  }
}
export function setGetPlansTreeCancelToken(cancelToken) {
  return {
    type: actionTypes.SET_GET_PLANS_TREE_CANCEL_TOKEN,
    cancelToken,
  }
}

export function moveItemsBatch(projectId, to, retries = 1) {
  return {
    type: actionTypes.MOVE_ITEMS_BATCH,
    projectId,
    to,
    retries,
  }
}
export function clearMoveItemsBatch() {
  return {
    type: actionTypes.CLEAR_MOVE_ITEMS_BATCH,
  }
}
export function itemsBatchMoved() {
  return {
    type: actionTypes.ITEMS_BATCH_MOVED,
  }
}
export function moveItemsBatchError(error) {
  return {
    type: actionTypes.MOVE_ITEMS_BATCH_ERROR,
    error,
  }
}
