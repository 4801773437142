import { createGlobalStyle, keyframes } from 'styled-components'
import dropdownArrow from '@images/downarrow.svg'
import grayscale from '@images/grayscale.svg'
import loadingIcon from '@images/ic_40_waiting_wheel.svg'
import colorsOptions from './components/stories/fundamentals/colors-styles/constants'
import {
  sizing,
  weights
} from './components/stories/fundamentals/text-styles/text-styles'

/* eslint no-unused-expressions: 0 */
/* Fonts Family Defined */
/* eslint-disable max-len */

const spinAnimationKeyframe = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`

export default createGlobalStyle`

  @font-face {
    font-family: "Raleway-Bold";
    font-display: swap;
    src: url('/fonts/Raleway/Raleway-Bold.ttf') format("truetype");
    font-feature-settings: "lnum";
  }

  @font-face {
    font-family: "Raleway-Regular";
    font-display: swap;
    src: url('/fonts/Raleway/Raleway-Regular.ttf') format("truetype");
    font-feature-settings: "lnum";
  }

  @font-face {
    font-family: "Raleway-SemiBold";
    font-display: swap;
    src: url('/fonts/Raleway/Raleway-SemiBold.ttf') format("truetype");
    font-weight: normal;
    font-feature-settings: "lnum";
  }

  @font-face {
    font-family: "Raleway-Medium";
    font-display: swap;
    src: url('/fonts/Raleway/Raleway-Medium.ttf') format("truetype");
    font-feature-settings: "lnum";
  }

  html,
  body {
    height: 100%;
    width: 100%;

    ${weights['text-500']};
    font-feature-settings: "lnum";
    letter-spacing: 0.013rem;

    &.fontLoaded {
      font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }

  input {
    border: none;
  }
  textarea {
    border: none;
  }

  button {
    outline: none;
    border: none;
    padding: 0;
    background: transparent;
  }

  p {
    margin: 0;
  }

  /* .css-dt-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='56' height='56' viewBox='0 0 56 56'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bclip-path:url(%23a);%7D.c%7Bfill:%23f6f6fa;%7D%3C/style%3E%3CclipPath id='a'%3E%3Crect class='a' width='56' height='56'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='b'%3E%3Cpath class='c' d='M266.815,28.479a5.506,5.506,0,0,1-.992,1.759,4.45,4.45,0,0,1-1.474,1.13,4.247,4.247,0,0,1-1.857.4,4.317,4.317,0,0,1-3.4-1.518,5.866,5.866,0,0,1-1.332-4.024,6.746,6.746,0,0,1,.364-2.245,5.415,5.415,0,0,1,.992-1.759,4.382,4.382,0,0,1,3.375-1.533,4.28,4.28,0,0,1,3.356,1.518,5.866,5.866,0,0,1,1.331,4.024,6.874,6.874,0,0,1-.368,2.245M262.663,15.41c-6.161,0-11.153,4.668-11.153,10.426,0,7.532,11.153,21.574,11.153,21.574s11.153-14.042,11.153-21.574c0-5.758-5-10.426-11.153-10.426' transform='translate(-234.51 -3.41)'/%3E%3Crect class='a' width='56' height='56'/%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
  }

  .css-dt-icon-active {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='56' height='56' viewBox='0 0 56 56'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bclip-path:url(%23a);%7D.c%7Bfill:%23f63;%7D%3C/style%3E%3CclipPath id='a'%3E%3Crect class='a' width='56' height='56'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='b'%3E%3Cpath class='c' d='M266.815,28.479a5.506,5.506,0,0,1-.992,1.759,4.45,4.45,0,0,1-1.474,1.13,4.247,4.247,0,0,1-1.857.4,4.317,4.317,0,0,1-3.4-1.518,5.866,5.866,0,0,1-1.332-4.024,6.746,6.746,0,0,1,.364-2.245,5.415,5.415,0,0,1,.992-1.759,4.382,4.382,0,0,1,3.375-1.533,4.28,4.28,0,0,1,3.356,1.518,5.866,5.866,0,0,1,1.331,4.024,6.874,6.874,0,0,1-.368,2.245M262.663,15.41c-6.161,0-11.153,4.668-11.153,10.426,0,7.532,11.153,21.574,11.153,21.574s11.153-14.042,11.153-21.574c0-5.758-5-10.426-11.153-10.426' transform='translate(-234.51 -3.41)'/%3E%3Crect class='a' width='56' height='56'/%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
  } */

  /* Custom React Select Styling */

  .Select__control {
    & > * {
      max-height: 100%;
    }
  }

  .Custom-Select, .Custom-Select-Rounded, .Custom-Select-Bottom-Border-Only, .Custom-Select-Docu, .Custom-Select-Category {
    .Select__control {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      //background: url("${dropdownArrow}") center right  no-repeat;
      background-size:14px 14px;
      border-radius: 0;
      cursor:pointer;
      &:hover{
        box-shadow:none;
      }

      .Select__input{
        padding:0 !important;
      }

      .Select__arrow-zone {
        opacity:0;
      }
    }

    .Select__control--is-focused, .Select__control--is-focused:hover {
      border-color: ${colorsOptions.greys['grey-20']};
      box-shadow:none;
    }

    .Select__menu {
    border-radius: 0;
    }

    .Select__input {
      height:22px;
      line-height:16px;
    }
    .Select__input > input {
      line-height:22px;
      padding:0;
      ${weights['text-600']};
    }

    .Select--single > .Select__control .Select__value {
      line-height:22px;
    }

    .Select__option{
      ${weights['text-600']};
      color: ${colorsOptions.greys['grey-100']};
      word-wrap:break-word;
    }

    .Select__option--is-focused, .Select__option--is-selected {
      background-color: ${colorsOptions.greys['grey-20']};
      color: ${colorsOptions.greys['grey-100']}
    }

    .Select__option:active {
      background-color: ${colorsOptions.greys['grey-50']};
    }

    .is-focused:not(.is-open) > .Select__control {
      border-color: transparent !important;
      box-shadow: none !important;
    }

    .Select__placeholder, .Select__-single > .Select__control .Select__value {
      padding-right:0 !important;
      padding-left:0 !important;
      ${weights['text-600']};
    }

    .Select__placeholder {
      color: ${colorsOptions.greys['grey-50']} !important;
    }

    .Select__clear-zone {
      display:none !important;
    }

    .Select__indicator-separator {
      width: 0;
    }

  .Select__menu {
    margin-top: 0;
    box-shadow: none;
    border: 1px solid ${colorsOptions.greys['grey-20']};
    border-top: none;
  }

  }

  .Custom-Select-Rounded {
    .Select__control{
      border-radius: 20px;
    }
    .Select__menu {
      margin-top: -13px;
    }
  }

  .Custom-Select-Bottom-Border-Only, .Custom-Select-Docu, .Custom-Select-Category {
    width: 100%;
    .Select__control{
      border: none;
      border-bottom: 1px solid ${colorsOptions.greys['grey-50']};
    }
    .Select__value-container {
      padding-left: 0;
    }
  }

  .Custom-Select-Category {
    .Select-control {

      .Select-placeholder {
        line-height: 26px;
      }

      .Select-value {
        line-height: 26px !important;

        .Select-value-label {
          line-height: 26px;
        }
      }

      .Select-input {
        height: 10px;
      }
    }
    .Select__menu {
      border: none;
      .Select__menu-list {
        height: 110px;
      }
    }
    .Select__input {
      height:22px;
      line-height:16px;
    }
    .Select__option--is-focused, .Select__option--is-selected {
      background-color: ${colorsOptions.docuToolsOrange['dt-10']};
    }
  }

  .Custom-Select-Docu {
    .Select__option {
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d5d5d5;
    }
    .Select__option:last-child {
      border-bottom: none;
    }

    .Select__option--is-focused {
      background-color: ${colorsOptions.greys['grey-20']}!important;
    }

    .Select__menu {
      box-shadow: -1px 3px 6px 2px #e3e3e3;
    }
  }

  .round-select {
   .Select-value {
     margin-top: -5px;
   }
  }

  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  h1, h2, h3, h4, h5, h6, p{
    font-weight:normal;
    margin:0;
    padding:0;
  }

  table {
    thead {
      th {
        font-weight:normal;
      }
    }
  }

  #root {
    background: ${colorsOptions.greys['grey-10']};
    min-height: 100%;
    min-width: 100%;
  }

  ul {
    li {
      list-style-type:none;
    }
  }

  /* Place holder css for all input fields */
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: ${colorsOptions.greys['grey-50']};
    opacity:1;
    font-weight:normal;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: ${colorsOptions.greys['grey-50']};;
    opacity:1;
    font-weight:normal;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: ${colorsOptions.greys['grey-50']};;
    opacity:1;
    font-weight:normal;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: ${colorsOptions.greys['grey-50']};;
    opacity:1;
    font-weight:normal;
  }

  //Input fields on focus outline removed
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: 0;
  }

  *:focus {
    outline-style: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }

  /* Monochromatic Plan View Style */
  #plan.monochromatic .leaflet-tile-container{
    -webkit-filter: saturate(0%) grayscale(100%) brightness(60%) contrast(1000%); /* Safari 6.0 - 9.0 */
    filter: saturate(0%) grayscale(100%) brightness(60%) contrast(1000%);
  }
  /* Grayscale Plan */
  #plan.grays .leaflet-tile-container {
    filter: url(${grayscale});
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1);
    -o-filter: grayscale(1);
    filter: grayscale(1);
    filter: gray;
  }

  /* Brightness Plan */
   #plan.brightness .leaflet-tile-container{
    -webkit-filter: brightness(140%);
    filter: brightness(140%);
    -moz-filter: brightness(140%);
    -ms-filter: brightness(140%);
    -o-filter: brightness(140%);
   }

  /* ToolTip Styling */
  .Tooltip{
    border:1px solid ${colorsOptions.greys['grey-20']} !important;
    opacity:1 !important;
    ${sizing['text-s']};
    font-size: ${sizing['text-s'].fontSize} !important;
    font-family: ${weights['text-600'].fontFamily} !important;
  }

  .datePicker {
    width: 100%;
  }
  .react-datepicker__tether-element {
    z-index:999 !important;
  }
  .react-datepicker__header {
      background:none !important;
      border-bottom:none !important;
  }

  /* Email Field */
  invalid {
    box-shadow: none !important;
  }

  :-moz-submit-invalid {
    box-shadow: none !important;;
  }

  :-moz-ui-invalid {
    box-shadow:none !important;;
  }

  // CSS fixes

  button {
    border: none;
    cursor:pointer;
  }
  input {
    border: none;
  }
  textarea {
    border: none;
  }

  button:active{
      padding:auto;
  }
  *{margin:0; padding:0;}
  a, button{
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
  }
  input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {background-color:transparent;}
  .pagination::selection{background:none; color:#cbcbcb;}
  .pagination::-moz-selection{background:none; color:#cbcbcb;}
  .github-picker{width:112px !important;}

  // location search on map input
  .importMapSearchInput {
    background-color: ${(props) => props.background || 'white'};
    ${sizing['text-l']};
    padding:0 0 4px;
    border-bottom:1px solid ${colorsOptions.greys['grey-50']};
    width:100%;
    color:${colorsOptions.greys['grey-100']};
    ${weights['text-600']};
    display:block;
    border-radius:0;
    &:focus{
      outline-color: transparent;
      outline: 0 !important;
    }
  }
  .importMapSearchAutocompleteContainer {
    z-index: 200;
    position: absolute;
    top: 100%;
    background-color: ${colorsOptions.greys.white};
    border: 1px solid ${colorsOptions.greys['grey-50']};
    width: 100%;
    max-height:100px;
    overflow:auto;
  }

  .customscroll{
    div + div + div{
      right:0 !important;
      width:2px !important;
      }
  }

  input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px ${colorsOptions.greys.white} inset;
  }

    /* Custom React Select Styling */
    .round-select {
      .Select-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-size:14px 14px;
        background: url("${dropdownArrow}") no-repeat 97% center;
        cursor:pointer;
        border-radius:20px;
        &:hover{
        box-shadow:none;
        }

        .Select-placeholder{
          font-weight:500;
          ${weights['text-500']};
          opacity:1 !important;
          color: ${colorsOptions.greys['grey-100']} !important;
        }
        .Select-arrow-zone{
        opacity:0;
        }
      }

      .Select-input > input{
        ${weights['text-600']};
      }

      .Select-option{
        font-family: ${weights['text-600'].fontFamily} !important;
        color: ${colorsOptions.greys['grey-100']} !important;
        &.is-focused{
          background:${colorsOptions.greys['grey-20']} !important;
        }
        &.is-selected{
          background:${colorsOptions.greys['grey-10']} !important;
        }
      }

      .is-focused:not(.is-open) > .Select-control {
        border-color: transparent !important;
        box-shadow: none !important;
      }

      .Select-placeholder, .round-select .Select--single > .Select-control .Select-value{
        font-family: ${weights['text-500'].fontFamily} !important;
      }

      .Select-clear-zone{
        display:none !important;
      }

      &.square .Select-control{
        border-radius:0;
      }
    }


  /* Sketch Container */
  .canvas-container{
    margin:0 auto
  };

  /* Cross Icon on Fields IE */
  input::-ms-clear {
    display: none;
    width:0;
    height:0;
  }
  textarea::-ms-clear {
    display: none;
    width:0;
    height:0;
  }
  input::-ms-clear, input::-ms-reveal {
    display: none;
  }

  table{
    tr{
      th, td{
        word-wrap:break-word;
      }
    }
  }
  .ReactPDF__Error, .ReactPDF__Loader{
    padding: 20px 16px;
    ${weights['text-600']};
  }

  /* Select Block */
  .nopadding {
    .Select-placeholder, .Select--single > .Select-control .Select-value{
      padding-left:0;
      padding-right:0;
      font-family: ${weights['text-500'].fontFamily} !important;
    }

    .Select-control {
      border:none;
      border-bottom:1px solid ${colorsOptions.greys['grey-30']};
      border-radius:0;
      background: url("${dropdownArrow}") 100% 14px  no-repeat;
      background-size:14px 14px;
      ${sizing['text-l']};
      font-family: ${weights['text-600'].fontFamily} !important;
    }
    .Select {
      background:none;
    }
    .Select-clear{
      display:none;
    }
    .Select-arrow-zone{
      display:none;
    }
    .Select-value {
      padding-right:0 !important;
      padding-left:0 !important;
      .Select-value-label{
        color: ${colorsOptions.greys['grey-60']};
      }
    }
    .Select-placeholder {
      padding-left:0;
      font-family: ${weights['text-500'].fontFamily} !important;
    }
    .Select-input {
      padding-left:0;
      max-width:309px;
      @media(max-width:767px){
        max-width:239px;
      }
      width:100%;
      overflow-y:hidden;
      padding-right:0;
      background: ${colorsOptions.greys.white};
    }
    .Select-menu-outer{
      ${weights['text-600']};
      ${sizing['text-l']};
      color: ${colorsOptions.greys['grey-100']};
      word-wrap:break-word;
    }

    .Select-option{
      font-family: ${weights['text-600'].fontFamily} !important;
      color: ${colorsOptions.greys['grey-100']} !important;
      &.is-focused{
        background:${colorsOptions.greys['grey-20']} !important;
      }
      &.is-selected{
        background:${colorsOptions.greys['grey-10']} !important;
      }
    }
    .is-focused:not(.is-open) > .Select-control {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .withOverlayLoader, .noOverlayLoader {
    position: relative;
  }
  .noOverlayLoader > * {
    opacity: .4;
  }
  .withOverlayLoader:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colorsOptions.greys['grey-40']};
    opacity: .4;
  }
  .withOverlayLoader:before, .noOverlayLoader:before {
    content: '';
    position: absolute;
    opacity: .8;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    background: url("${loadingIcon}") no-repeat center;
    background-size: contain;
    animation: ${spinAnimationKeyframe} 2s linear infinite;
    display:block;
    margin:0 auto;
  }
`
