import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  badgeColorOptions,
  badgeSizeOptions,
  badgeComponentVariantOptions
} from './constants'
import { minimalVariant } from './styled-components'

const BadgesComponent = forwardRef(
  ({ size, variant, color, disabled = false, ...props }, fowardedRef) => {
    const Component = badgeComponentVariantOptions[variant] || minimalVariant
    return (
      <Component
        color={disabled ? 'disabled' : color}
        size={size}
        ref={fowardedRef}
        {...props}
      />
    )
  }
)

BadgesComponent.displayName = 'BadgesComponent'

BadgesComponent.propTypes = {
  size: PropTypes.oneOf(Object.keys(badgeSizeOptions)),
  variant: PropTypes.oneOf(Object.keys(badgeComponentVariantOptions)),
  color: PropTypes.oneOf(badgeColorOptions),
  disabled: PropTypes.bool
}

export default BadgesComponent
