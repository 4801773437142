import styled, { css } from 'styled-components'
import ReactTextAreaAutosize from 'react-autosize-textarea'
import { ifNotProp, ifProp, prop } from 'styled-tools'
import {
  sizing,
  weights
} from '../stories/fundamentals/text-styles/text-styles'
import colorsOptions from '../stories/fundamentals/colors-styles/constants'

const DefaultInput = styled.input`
  background-color: ${prop('background', 'white')};
  ${sizing['text-l']};
  padding: 0 0 4px;
  width: 100%;
  color: ${ifProp(
    'error',
    colorsOptions.secondaryColors.red.normal,
    colorsOptions.greys['grey-100']
  )};
  border-color: ${ifProp(
    'error',
    colorsOptions.secondaryColors.red.normal,
    colorsOptions.greys['grey-100']
  )};
  ${weights['text-500']};
  display: block;
  border-radius: 0;
  line-height: ${prop('lineHeight', '20px')};
  &:focus {
    outline-color: transparent;
    outline: 0 !important;
  }
  &:disabled {
    color: ${colorsOptions.greys['grey-50']};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${ifNotProp(
    'noBottomBorder',
    css`
      border-bottom: 1px solid
        ${prop('bottomBorderColor', colorsOptions.greys['grey-20'])};
    `
  )}
`

const Wrapper = styled.div`
  display: flex;
  border: 1px solid ${colorsOptions.greys['grey-30']};
  border-radius: ${prop('borderRadius', '8px')};
  height: ${prop('height', '56px')};
  align-items: center;
  background-color: ${colorsOptions.greys.white};
  padding-right: 16px;
  ${ifNotProp('icon', 'padding-left: 16px;')}
  width: ${prop('width', '100%')};
  input {
    ${sizing['text-r']};
  }
`

const Textarea = styled.textarea`
  background-color: ${(props) => props.background || 'white'};
  ${sizing['text-l']};
  padding: 7px 0;
  border-bottom: 1px solid ${colorsOptions.greys['grey-20']};
  width: 100%;
  color: ${colorsOptions.greys['grey-100']};
  ${weights['text-600']};
  display: block;
  border-radius: 0;
  &:focus {
    outline-color: transparent;
    outline: 0 !important;
  }
`

const AutoTextarea = styled(ReactTextAreaAutosize)`
  background-color: ${(props) => props.background || 'white'};
  ${sizing['text-l']};
  padding: 0 0 4px;
  border-bottom: 1px solid ${colorsOptions.greys['grey-50']};
  width: 100%;
  color: ${colorsOptions.greys['grey-100']};
  ${weights['text-600']};
  display: block;
  border-radius: 0;
  &:focus {
    outline-color: transparent;
    outline: 0 !important;
  }
`

const AutoTextareaTwo = styled.textarea`
  background-color: ${(props) => props.background || 'white'};
  ${sizing['text-l']};
  padding: 0 0 4px;
  border-bottom: 1px solid ${colorsOptions.greys['grey-50']};
  width: 100%;
  color: ${colorsOptions.greys['grey-100']};
  ${weights['text-600']};
  display: block;
  border-radius: 0;
  max-height: 200px;

  &:focus {
    outline-color: transparent;
    outline: 0 !important;
  }
`

export { DefaultInput, Wrapper, Textarea, AutoTextarea, AutoTextareaTwo }
