import { bool, func, object, oneOf, string } from 'prop-types'
import {
  ActionButtonWrapper,
  Custombtn,
  DownloadButton,
  LoaderWrapper,
  RoundIconButton
} from '../styled-components'
import { buttonType } from '../constants'
import { InlineLoader } from '../../page-loader/styled-components'
import Button, { BUTTON_TYPES } from '../../Button'
import { AboutLink } from '../../../containers/Reports/styledComponents'
import AddNewFieldButton from '../../../containers/ProjectSettings/components/PinDesign/AddNewFieldButton'

const PopupNewActionButton = ({
  hidden,
  className,
  type,
  onClick,
  disabled,
  text,
  size,
  testId,
  href,
  download,
  target,
  icon,
  altText,
  as,
  styles,
  pending,
  bType,
  ...props
}) => {
  if (hidden) {
    return null
  }

  return (
    <>
      {type === buttonType.LINK ? (
        <AboutLink
          href={href}
          target={target}
          disabled={disabled}
          className={className}
          style={styles}
        >
          {text}
        </AboutLink>
      ) : (
        <ActionButtonWrapper>
          {(!type ||
            type === buttonType.CANCEL ||
            type === buttonType.DELETE) && (
            <Custombtn
              style={styles}
              marginRight={styles && styles.marginRight}
              data-testid={testId}
              className={className}
              onClick={onClick}
              disabled={disabled}
              buttonType={type}
              size={size}
              pending={pending}
            >
              <img src={icon} alt={altText} className={className} />
              <span>{text}</span>
            </Custombtn>
          )}
          {type === buttonType.DOWNLOAD && (
            <DownloadButton
              as={disabled ? 'button' : undefined}
              data-testid={testId}
              className={className}
              onClick={onClick}
              disabled={disabled}
              buttonType={type}
              size={size}
              href={href}
              target={target}
              download={download}
              pending={pending}
            >
              {text}
            </DownloadButton>
          )}
          {type === buttonType.A && (
            <DownloadButton
              data-testid={testId}
              className={className}
              onClick={onClick}
              disabled={disabled}
              buttonType={type}
              size={size}
              href={href}
              target={target}
              pending={pending}
              style={styles}
            >
              {text}
            </DownloadButton>
          )}
          {type === buttonType.BUTTON && (
            <Button
              data-testid={testId}
              className={className}
              onClick={onClick}
              disabled={disabled}
              size={size}
              type={bType}
              href={href}
              target={target}
              pending={pending}
              style={styles}
              as={as}
              {...props}
            >
              {text}
            </Button>
          )}
          {type === buttonType.ROUND_ICON && (
            <RoundIconButton
              data-testid={testId}
              className={className}
              onClick={onClick}
              disabled={disabled}
              buttonType={type}
              size={size}
              href={href}
              target={target}
              as={as}
              pending={pending}
            >
              <img src={icon} alt={altText} />
            </RoundIconButton>
          )}
          {type === BUTTON_TYPES.dropdown && (
            <AddNewFieldButton
              data-testid={testId}
              className={className}
              altText={altText}
              icon={icon}
              text={text}
              styles={styles}
              {...props}
            />
          )}
          {pending && (
            <LoaderWrapper>
              <InlineLoader />
            </LoaderWrapper>
          )}
        </ActionButtonWrapper>
      )}
    </>
  )
}

export default PopupNewActionButton

PopupNewActionButton.propTypes = {
  hidden: bool,
  className: string,
  onClick: func,
  disabled: bool,
  type: string,
  text: string,
  size: string,
  testId: string,
  href: string,
  download: string,
  target: string,
  icon: string,
  altText: string,
  as: string,
  styles: object,
  pending: bool,
  bType: oneOf(Object.values(BUTTON_TYPES))
}
