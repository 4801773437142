import PropTypes from 'prop-types'
import {
  buttonSizeOptions,
  buttonTypeOptions,
  buttonVariantOptions,
  buttonLinkSizeOptions,
  buttonVariantComponentOptions
} from './constants'
import { PrimaryButton } from './styled-components'
import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import { SpinnerWhite } from '../icons/special'

const ButtonComponent = forwardRef(
  (
    {
      as,
      to = null,
      target = null,
      variant,
      size,
      emphasis,
      buttonType,
      fullWidth,
      isLoading = false,
      children,
      ...props
    },
    ref
  ) => {
    const isVariantLink = variant === 'link'

    const isLink = variant === 'link' || as === Link || as === 'a'

    const isExternalLink =
      isLink && (to?.startsWith('http') || to?.startsWith('https'))

    const Component = buttonVariantComponentOptions[variant] || PrimaryButton

    const contentChildren = isLoading ? <SpinnerWhite /> : children

    const componentProps = {
      as: isLink ? (isExternalLink ? 'a' : Link) : as || 'button',
      ref,
      ...(isVariantLink
        ? { linksize: size, emphasis }
        : { size, buttonType, fullWidth }),
      ...props
    }

    if (isExternalLink) {
      componentProps.href = to
      componentProps.target = target ?? '_blank'
      componentProps.rel = 'noopener noreferrer'
    } else if (isLink) {
      componentProps.to = to
    }

    return <Component {...componentProps}> {contentChildren} </Component>
  }
)

// Dynamically determine size prop buttonType based on the variant
const sizePropType = (props, propName, componentName) => {
  const { variant, size } = props
  const validSizes =
    variant === 'link'
      ? Object.values(buttonLinkSizeOptions)
      : Object.values(buttonSizeOptions)

  if (!validSizes.includes(size)) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. ` +
        `Expected one of: ${validSizes.join(', ')}.`
    )
  }
  return null
}

ButtonComponent.propTypes = {
  as: PropTypes.elementType,
  variant: PropTypes.oneOf(Object.values(buttonVariantOptions)),
  size: sizePropType,
  buttonType: PropTypes.oneOf(Object.values(buttonTypeOptions)),
  fullWidth: PropTypes.bool,
  emphasis: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  isLoading: PropTypes.bool
}

ButtonComponent.displayName = 'ButtonComponent'

export default ButtonComponent
