import { InlineLoader } from '../page-loader/styled-components'
import PopupNew from '../popup-new'
import { popupSize } from '../popup-new/constants'

export const AsyncLoaderPopup = (
  <PopupNew
    title=""
    size={popupSize.SMALL}
    contentText={<InlineLoader />}
    noFooter
    overPopup
  />
)
