import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import {
  buttonSizeOptions,
  buttonTypeOptions,
  buttonLinkStyleOptions,
  buttonLinkSizeOptions,
  buttonSizeStyleOptions
} from './constants'
import colorsOptions from '../../fundamentals/colors-styles/constants'
import { weights } from '../../fundamentals/text-styles/text-styles'
import { motion } from 'framer-motion'

const ButtonBase = styled(motion.button)`
  all: unset;
  box-sizing: border-box;
  width: ${ifProp('fullWidth', '100%')};
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 84px;
  ${weights['text-600']}

  ${({ size = buttonSizeOptions.MEDIUM }) => css`
    ${buttonSizeStyleOptions[size]}
  `}

  & svg {
    ${({ size = buttonSizeOptions.MEDIUM }) => css`
      ${buttonSizeStyleOptions[size].iconSize}
      stroke: inherit;
    `}
    & path {
      stroke: inherit;
      fill: inherit;
    }
  }

  ${({ buttonType }) =>
    buttonType === buttonTypeOptions['ICON-ONLY'] &&
    css`
      padding: 0;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      ${({ size = buttonSizeOptions.MEDIUM }) => css`
        width: ${buttonSizeStyleOptions[size]?.height};
      `}
    `}

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline-style: solid;
    outline-width: 2px;
    outline-color: ${colorsOptions.docuToolsOrange.orange};
    outline-offset: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: '50%';
  }

  padding: ${ifProp('fullWidth', 0)};
`

const PrimaryButton = styled(ButtonBase)`
  background-color: ${colorsOptions.docuToolsOrange.orange};
  color: ${colorsOptions.greys.white};
  stroke: ${colorsOptions.greys.white};
  fill: ${colorsOptions.greys.white};
  &:hover {
    background-color: ${colorsOptions.docuToolsOrange['dt-40']};
  }
  &:active {
    background-color: ${colorsOptions.docuToolsOrange['dt-50']};
  }
  &:disabled {
    background-color: ${colorsOptions.greys['grey-40']};
    color: ${colorsOptions.greys['grey-10']};
  }
`

const PrimaryDestructiveButton = styled(PrimaryButton)`
  background-color: ${colorsOptions.semantics.danger.error};
  &:hover {
    background-color: ${colorsOptions.semantics.danger['error-50']};
  }
  &:active {
    background-color: ${colorsOptions.semantics.danger['error-60']};
  }
`

const SecondaryHollowButton = styled(ButtonBase)`
  background-color: transparent;
  color: ${colorsOptions.greys['grey-90']};
  stroke: ${colorsOptions.greys['grey-90']};
  fill: ${colorsOptions.greys['grey-90']};
  border-style: solid;
  border-color: ${colorsOptions.greys['grey-90']};

  &:hover {
    background-color: ${colorsOptions.greys['grey-20']};
  }
  &:active {
    background-color: ${colorsOptions.greys['grey-30']};
  }

  &:disabled {
    background-color: transparent;
    color: ${colorsOptions.greys['grey-40']};
    stroke: ${colorsOptions.greys['grey-40']};
    fill: ${colorsOptions.greys['grey-40']};
    border-color: ${colorsOptions.greys['grey-40']};
  }
`
const SecondaryWhiteFillButton = styled(SecondaryHollowButton)`
  background-color: ${colorsOptions.greys.white};
`

const SecondaryInvertedButton = styled(ButtonBase)`
  background-color: transparent;
  color: ${colorsOptions.greys.white};
  stroke: ${colorsOptions.greys.white};
  fill: ${colorsOptions.greys.white};
  border-style: solid;
  border-color: ${colorsOptions.greys.white};

  &:hover {
    background-color: ${colorsOptions.greys['grey-80']};
  }
  &:active {
    background-color: ${colorsOptions.greys['grey-70']};
  }
  &:disabled {
    background-color: transparent;
    color: ${colorsOptions.greys['grey-60']};
    stroke: ${colorsOptions.greys['grey-60']};
    fill: ${colorsOptions.greys['grey-60']};
    border-color: ${colorsOptions.greys['grey-60']};
  }
`

const SecondaryDestructiveHollowButton = styled(ButtonBase)`
  background-color: transparent;
  color: ${colorsOptions.semantics.danger['error-60']};
  stroke: ${colorsOptions.semantics.danger['error-60']};
  fill: ${colorsOptions.semantics.danger['error-60']};
  border-style: solid;
  border-color: ${colorsOptions.semantics.danger['error-60']};

  &:hover {
    background-color: ${colorsOptions.semantics.danger['error-10']};
  }
  &:active {
    background-color: ${colorsOptions.semantics.danger['error-20']};
  }
  &:disabled {
    background-color: transparent;
    color: ${colorsOptions.greys['grey-40']};
    stroke: ${colorsOptions.greys['grey-40']};
    fill: ${colorsOptions.greys['grey-40']};
    border-color: ${colorsOptions.greys['grey-40']};
  }
`

const SecondaryDestructiveWhiteFillButton = styled(
  SecondaryDestructiveHollowButton
)`
  background-color: ${colorsOptions.greys.white};
`

const SecondaryDestructiveInvertedButton = styled(ButtonBase)`
  background-color: transparent;
  color: ${colorsOptions.semantics.danger.error};
  stroke: ${colorsOptions.semantics.danger.error};
  fill: ${colorsOptions.semantics.danger.error};
  border-style: solid;
  border-color: ${colorsOptions.semantics.danger.error};

  &:hover {
    background-color: ${colorsOptions.greys['grey-80']};
  }
  &:active {
    background-color: ${colorsOptions.greys['grey-70']};
  }
  &:disabled {
    background-color: transparent;
    color: ${colorsOptions.greys['grey-60']};
    stroke: ${colorsOptions.greys['grey-60']};
    fill: ${colorsOptions.greys['grey-60']};
    border-color: ${colorsOptions.greys['grey-60']};
  }
`

const TertiaryHollowButton = styled(ButtonBase)`
  background-color: transparent;
  color: ${colorsOptions.greys['grey-90']};
  stroke: ${colorsOptions.greys['grey-90']};
  fill: ${colorsOptions.greys['grey-90']};
  border-style: solid;
  border-color: ${colorsOptions.greys['grey-20']};

  &:hover {
    background-color: ${colorsOptions.greys['grey-20']};
  }
  &:active {
    background-color: ${colorsOptions.greys['grey-30']};
  }
  &:disabled {
    background-color: transparent;
    color: ${colorsOptions.greys['grey-40']};
    stroke: ${colorsOptions.greys['grey-40']};
    fill: ${colorsOptions.greys['grey-40']};
    border-color: ${colorsOptions.greys['grey-40']};
  }
`

const TertiaryWhiteFillButton = styled(TertiaryHollowButton)`
  background-color: ${colorsOptions.greys.white};
`

const GhostButton = styled(ButtonBase)`
  background-color: transparent;
  color: ${colorsOptions.greys['grey-90']};
  stroke: ${colorsOptions.greys['grey-90']};
  fill: ${colorsOptions.greys['grey-90']};
  border: none;

  &:hover {
    background-color: ${colorsOptions.greys['grey-20']};
  }
  &:active {
    background-color: ${colorsOptions.greys['grey-30']};
  }
  &:disabled {
    background-color: transparent;
    color: ${colorsOptions.greys['grey-40']};
    stroke: ${colorsOptions.greys['grey-40']};
    fill: ${colorsOptions.greys['grey-40']};
  }
`

const GhostInvertedButton = styled(ButtonBase)`
  background-color: transparent;
  color: ${colorsOptions.greys.white};
  stroke: ${colorsOptions.greys.white};
  fill: ${colorsOptions.greys.white};
  border: none;

  &:hover {
    background-color: ${colorsOptions.greys['grey-80']};
  }
  &:active {
    background-color: ${colorsOptions.greys['grey-70']};
  }
  &:disabled {
    background-color: transparent;
    color: ${colorsOptions.greys['grey-60']};
    stroke: ${colorsOptions.greys['grey-60']};
    fill: ${colorsOptions.greys['grey-60']};
  }
`
const GhostDestructiveButton = styled(GhostButton)`
  color: ${colorsOptions.semantics.danger['error-60']};
  stroke: ${colorsOptions.semantics.danger['error-60']};
  fill: ${colorsOptions.semantics.danger['error-60']};

  &:hover {
    background-color: ${colorsOptions.semantics.danger['error-10']};
  }
  &:active {
    background-color: ${colorsOptions.semantics.danger['error-20']};
  }
`

const GhostDestructiveInvertedButton = styled(GhostInvertedButton)`
  color: ${colorsOptions.semantics.danger.error};
  stroke: ${colorsOptions.semantics.danger.error};
  fill: ${colorsOptions.semantics.danger.error};
`

const LinkButton = styled(ButtonBase)`
  all: unset;
  ${weights['text-500']}
  color: ${colorsOptions.greys['grey-70']};

  ${({ emphasis }) =>
    emphasis &&
    css`
      color: ${colorsOptions.docuToolsOrange.orange};
    `}

  ${({ linkSize = buttonLinkSizeOptions.MEDIUM }) => css`
    ${buttonLinkStyleOptions[linkSize]};
  `}

  &:active {
    color: ${colorsOptions.greys['grey-80']};

    ${({ emphasis }) =>
      emphasis &&
      css`
        color: ${colorsOptions.docuToolsOrange['dt-40']};
      `}
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:disabled {
    color: ${colorsOptions.greys['grey-50']};
  }
`

export {
  PrimaryButton,
  PrimaryDestructiveButton,
  SecondaryHollowButton,
  SecondaryWhiteFillButton,
  SecondaryInvertedButton,
  SecondaryDestructiveHollowButton,
  SecondaryDestructiveWhiteFillButton,
  SecondaryDestructiveInvertedButton,
  TertiaryHollowButton,
  TertiaryWhiteFillButton,
  GhostButton,
  GhostInvertedButton,
  GhostDestructiveButton,
  GhostDestructiveInvertedButton,
  LinkButton
}
