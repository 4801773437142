import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { ifProp, prop } from 'styled-tools'
import { createStructuredSelector } from 'reselect'
import { makeSelectIsMobile } from './selectors'
import colorsOptions from '../../components/stories/fundamentals/colors-styles/constants'

const Content = styled.div`
  overflow: ${ifProp('overflowHidden', 'hidden', 'auto')};
  height: 100%;
  position: relative;
  transition: margin-left 0.5s ease;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
  ${ifProp(
    'isMobile',
    css`
      margin-left: 0;
      background: ${colorsOptions.greys.white};
    `,
    css`
      background: ${colorsOptions.greys['grey-10']};
      margin-left: ${prop('left')}px;
    `,
  )}
`

const mapStateToProps = createStructuredSelector({
  isMobile: makeSelectIsMobile(),
})

export default connect(mapStateToProps)(Content)
