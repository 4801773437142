import { Component, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
  getRootPlans as getRootPlansAction,
  clearPlans as clearPlansAction
} from '../PlanSelection/actions'
import {
  getProjectDetails as getProjectDetailsAction,
  clearProjectDetails as clearProjectDetailsAction
} from '../ProjectSettings/actions'
import {
  makeSelectProjectDetails,
  makeSelectGetProjectDetailsError
} from '../ProjectSettings/selectors'
import { makeSelectGetPlanRevisionError } from '../Plan/selectors'
import { Loader, NoBgLoaderWrapper } from '../../components/page-loader/styled-components'
import { makeSelectLoggedIn } from '../Authentication/selectors'
import * as quickSelectActions from '../QuickSelect/actions'
import { AsyncLoaderPage } from '../../components/async-loader/async-loader-page'

import * as selectors from './selectors'
import { withRouter } from '../../utils/hocs'

const PlanSelection = lazy(
  () => import(/* webpackChunkName: "PlanSelection" */ '../PlanSelection')
)
const PlanViewerNew = lazy(
  () => import(/* webpackChunkName: "PlanViewerNew" */ '../PlanViewerNew')
)
const TeamManagement = lazy(
  () =>
    import(/* webpackChunkName: "TeamManagementUsers" */ '../TeamsManagement')
)
const TaskList = lazy(
  () => import(/* webpackChunkName: "TaskList" */ '../TaskList')
)
const Datasets = lazy(
  () => import(/* webpackChunkName: "Datasets" */ '../Datasets')
)
const Media = lazy(() => import(/* webpackChunkName: "Media" */ '../Media'))
const Reports = lazy(
  () => import(/* webpackChunkName: "Reports" */ '../Reports')
)
const Groups = lazy(() => import(/* webpackChunkName: "Groups" */ '../Groups'))
const Group = lazy(() => import(/* webpackChunkName: "Group" */ '../Group'))
const PlanVersions = lazy(
  () => import(/* webpackChunkName: "PlanVersions" */ '../PlanVersions')
)
const NotFoundPage = lazy(
  () => import(/* webpackChunkName: "NotFoundPage" */ '../NotFoundPage')
)

export class ProjectRouter extends Component {
  constructor (props) {
    super(props)
    const { projectId } = props.match.params
    const { projectDetails } = props

    if (projectId) {
      props.clearPlans()
      props.getRootPlans(projectId, 0, 'Title', 'Active', '')
      if (!projectDetails || projectDetails.id !== projectId) {
        props.clearProjectDetails()
        props.getProjectDetails(projectId)
      }
    }

    this.state = {
      props: {
        projectId
      }
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { projectId: prevProjectId } = prevState.props

    const {
      match: {
        params: { projectId },
        path
      },
      clearPlans,
      getRootPlans,
      clearProjectDetails,
      getProjectDetails
    } = nextProps

    if (projectId && projectId !== prevProjectId) {
      if (!['/project/:projectId/:move?'].includes(path)) {
        clearPlans()
        getRootPlans(projectId, 0, 'Title', 'Active', '')
      }
      clearProjectDetails()
      getProjectDetails(projectId)
    }

    return {
      props: {
        projectId
      }
    }
  }

  render () {
    const {
      projectDetails,
      projectDetailsError,
      planRevisionError,
      history,
      match: {
        params: { projectId }
      }
    } = this.props

    if (
      (!!projectDetailsError && projectDetailsError.status === 403) ||
      planRevisionError?.status === 403
    ) {
      history.push('/project', { data: 'permission denied' })
    }

    if (projectDetails && projectDetails.id === projectId) {
      return (
        <>
          <Suspense fallback={AsyncLoaderPage}>
            <Switch>
              <Route path="/project/:projectId/team/importStatus">
                <TeamManagement />
              </Route>
              <Route path="/project/:projectId/team/:viewType?">
                <TeamManagement />
              </Route>
              <Route path="/project/:projectId/team/:viewType/importStatus">
                <TeamManagement />
              </Route>
              <Route exact path="/project/:projectId/tasks">
                <TaskList />
              </Route>
              <Route exact path="/project/:projectId/tasks/new">
                <TaskList />
              </Route>
              <Route exact path="/project/:projectId/tasks/importStatus">
                <TaskList />
              </Route>
              <Route exact path="/project/:projectId/datasets">
                <Datasets />
              </Route>
              <Route exact path="/project/:projectId/datasets/importStatus">
                <Datasets />
              </Route>
              <Route exact path="/project/:projectId/datasets/:datasetId">
                <Datasets />
              </Route>
              <Route
                exact
                path="/project/:projectId/datasets/:datasetId/importStatus"
              >
                <Datasets />
              </Route>
              <Route exact path="/project/:projectId/media">
                <Media />
              </Route>
              <Route exact path="/project/:projectId/media/importStatus">
                <Media />
              </Route>
              <Route path="/project/:projectId/media/gallery/:year/:month/:fileId">
                <Media />
              </Route>
              <Route path="/project/:projectId/reports">
                <Reports />
              </Route>
              <Route path="/project/:projectId/reports/importStatus">
                <Reports />
              </Route>
              <Route exact path="/project/:projectId/groups">
                <Groups />
              </Route>
              <Route exact path="/project/:projectId/groups/importStatus">
                <Groups />
              </Route>

              <Route
                exact
                path="/project/:projectId/groups/:groupId/:viewType/:planId?/:pinId?/:tab?/:pinOption?/:page?/:size?/:search?"
              >
                <Group />
              </Route>

              <Route exact path="/project/:projectId/planVersions/:planId">
                <PlanVersions />
              </Route>
              <Route
                exact
                path="/project/:projectId/planVersions/:planId/importStatus"
              >
                <PlanVersions />
              </Route>

              <Route path="/project/:projectId/move">
                <PlanSelection />
              </Route>

              <Route path="/project/:projectId/settings">
                <PlanSelection />
              </Route>
              <Route path="/project/:projectId/pins/:viewType/noPlan/:pinId?/:tab?/:pinOption?/:page?/:size?/:search?">
                <PlanViewerNew />
              </Route>
              <Route path="/project/:projectId/pins/:viewType/:planId?/:pinId?/:tab?/:pinOption?/:page?/:size?/:search?">
                <PlanViewerNew />
              </Route>
              <Route path="/project/:projectId/pins/:viewType/:planId?/:pinId?/:tab?/:pinOption?/:search?">
                <PlanViewerNew />
              </Route>

              <Route exact path="/project/:projectId/import">
                <PlanSelection />
              </Route>
              <Route
                exact
                path="/project/:projectId/importRevision/:planId/:option?"
              >
                <PlanSelection />
              </Route>
              <Route exact path="/project/:projectId/importStatus">
                <PlanSelection />
              </Route>
              <Route exact path="/project/:projectId/rename/:planId">
                <PlanSelection />
              </Route>
              <Route exact path="/project/:projectId/newfolder/:parentId?">
                <PlanSelection />
              </Route>
              <Route path="/project/:projectId/:planId/:viewType/:pinId?/:tab?/:pinOption?/:search?">
                <PlanViewerNew />
              </Route>
              <Route path="/project/:projectId">
                <PlanSelection />
              </Route>

              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </>
      )
    }

    return (
      <NoBgLoaderWrapper>
        <Loader className="loader" />
      </NoBgLoaderWrapper>
    )
  }
}
ProjectRouter.propTypes = {
  match: PropTypes.object.isRequired,
  projectDetails: PropTypes.object,
  getRootPlans: PropTypes.func.isRequired,
  clearPlans: PropTypes.func.isRequired,
  clearProjectDetails: PropTypes.func.isRequired,
  getProjectDetails: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  projectDetailsError: PropTypes.object,
  planRevisionError: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  projectDetails: makeSelectProjectDetails(),
  projectDetailsError: makeSelectGetProjectDetailsError(),
  loggedIn: makeSelectLoggedIn(),
  menuOpen: selectors.makeSelectMenuOpen(),
  isMobile: selectors.makeSelectIsMobile(),
  isDesktop: selectors.makeSelectIsDesktop(),
  isTablet: selectors.makeSelectIsTablet(),
  planRevisionError: makeSelectGetPlanRevisionError()
})

function mapDispatchToProps (dispatch) {
  return {
    getRootPlans: (...attributes) =>
      dispatch(getRootPlansAction(...attributes)),
    clearPlans: (...attributes) => {
      dispatch(clearPlansAction(...attributes))
      dispatch(quickSelectActions.clearPlans())
    },
    getProjectDetails: (...attributes) =>
      dispatch(getProjectDetailsAction(...attributes)),
    clearProjectDetails: (...attributes) =>
      dispatch(clearProjectDetailsAction(...attributes))
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectRouter)
)
